/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWooxWhite7 = ({ className }) => {
  return (
    <svg
      className={`logo-woox-white-7 ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 140 30"
      width="140"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-3"
        d="M58.4815 0.582031C55.5994 0.582031 52.7786 1.44054 50.387 3.05537C47.9954 4.67019 46.1149 6.95956 45.0111 9.6373C43.9072 12.315 43.6211 15.279 44.173 18.1202C44.7453 20.9615 46.1149 23.5779 48.1589 25.6425C50.203 27.6865 52.799 29.0969 55.6198 29.6693C58.4407 30.2416 61.3841 29.935 64.0414 28.8312C66.6987 27.7274 68.9881 25.8469 70.5825 23.4144C72.1769 21.0024 73.0354 18.1611 73.0354 15.2585C73.0354 11.3543 71.5023 7.63411 68.7633 4.8746C66.0446 2.13553 62.3449 0.582031 58.4815 0.582031ZM58.4815 23.1896C56.928 23.1896 55.4154 22.7194 54.1277 21.8609C52.8399 20.982 51.8383 19.7555 51.2455 18.3042C50.6527 16.8529 50.4892 15.279 50.7958 13.7459C51.1024 12.2128 51.8383 10.8024 52.9421 9.69863C54.0459 8.59483 55.4359 7.83852 56.9689 7.5319C58.4815 7.22529 60.0759 7.38881 61.5068 7.9816C62.9376 8.57438 64.1641 9.59642 65.0226 10.8842C65.8811 12.1924 66.3512 13.705 66.3512 15.279C66.3512 17.3844 65.5336 19.3876 64.0619 20.8797C62.5493 22.3515 60.5665 23.1896 58.4815 23.1896Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M91.3299 0.582031C88.4478 0.582031 85.6269 1.44054 83.2354 3.05537C80.8438 4.67019 78.9632 6.95956 77.8594 9.6373C76.7556 12.315 76.4695 15.279 77.0214 18.1202C77.5733 20.9615 78.9632 23.5779 81.0073 25.6425C83.0514 27.6865 85.6474 29.0969 88.4682 29.6693C91.289 30.2416 94.2325 29.935 96.8898 28.8312C99.5471 27.7274 101.837 25.8469 103.431 23.4144C105.025 21.0024 105.884 18.1611 105.884 15.2585C105.884 11.3543 104.351 7.63411 101.612 4.8746C98.9135 2.13553 95.1932 0.582031 91.3299 0.582031ZM91.3299 23.1896C89.7764 23.1896 88.2638 22.7194 86.976 21.8609C85.6883 20.982 84.6867 19.7555 84.0939 18.3042C83.5011 16.8529 83.3376 15.279 83.6442 13.7459C83.9508 12.2128 84.6867 10.8024 85.7905 9.69863C86.8943 8.59483 88.2842 7.83852 89.8173 7.5319C91.3299 7.22529 92.9243 7.38881 94.3552 7.9816C95.786 8.57438 97.0125 9.59642 97.871 10.8842C98.7295 12.1924 99.1996 13.705 99.1996 15.279C99.1996 17.3844 98.382 19.3876 96.9103 20.8797C95.4181 22.3515 93.4149 23.1896 91.3299 23.1896Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M42.7014 0.827148H35.6084L32.3379 11.5995H39.3286L42.7014 0.827148Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M26.4508 11.0884L29.9666 19.3874L31.2339 15.1561H38.2042L34.2796 28.0339C33.973 29.0355 33.0327 29.7304 31.9698 29.7304H28.0043C27.0436 29.7304 26.1442 29.1377 25.7558 28.1974L21.4223 17.5068L17.1298 28.1565C16.7823 29.0559 15.8829 29.6896 14.8813 29.6896H10.8749C9.81197 29.6896 8.87169 28.9946 8.56508 27.993L0.266113 0.827148H7.25687L12.7759 19.367L16.4348 11.0067C17.232 8.92171 19.2352 7.57261 21.4223 7.57261C23.6708 7.57261 25.674 8.96259 26.4508 11.0884Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M121.316 7.42953L116.308 0.827148H107.601L116.962 13.1734L121.316 7.42953Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M138.855 0.847656H130.147L108.214 29.7305H116.922L138.855 0.847656Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M130.76 29.7305L125.752 23.1281L130.106 17.3843L139.468 29.7305H130.76Z"
        fill="white"
      />
    </svg>
  );
};
