/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite3 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-3 ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 89 15"
      width="89"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_8166)">
        <path
          className="path-3"
          d="M4.49836 0.549805L1.1123 14.1267H4.50563L6.62237 5.63797H10.0193L11.2886 0.549805H7.89168H4.49836Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M18.075 0.549805H14.6817L13.4124 5.63797L12.565 9.03494H9.17166L7.90234 14.1267H11.2957H14.689L16.8057 5.63797L18.075 0.549805Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M36.3912 1.37214C36.3939 1.49808 36.3692 1.6231 36.319 1.73861C36.2688 1.85413 36.1942 1.95741 36.1003 2.04136C35.9295 2.20928 35.6996 2.30338 35.4601 2.30338C35.2207 2.30338 34.9908 2.20928 34.82 2.04136C34.6456 1.87405 34.544 1.64484 34.5372 1.40325C34.5304 1.16166 34.6189 0.927086 34.7836 0.750218L34.8273 0.702945C34.998 0.535022 35.2279 0.440918 35.4674 0.440918C35.7069 0.440918 35.9368 0.535022 36.1075 0.702945C36.2015 0.786465 36.2757 0.889845 36.3247 1.00559C36.3738 1.12134 36.3965 1.24654 36.3912 1.37214ZM21.4795 0.546542H27.095C28.648 0.546542 29.8664 0.993894 30.652 1.75039C30.9365 2.03692 31.1605 2.37779 31.3104 2.75271C31.4604 3.12762 31.5333 3.52889 31.5249 3.9326V3.97262C31.5249 5.7402 30.4338 6.65309 29.3718 7.11499C30.983 7.60235 32.2814 8.53342 32.2814 10.3883V10.4283C32.2814 12.756 30.3247 14.1344 27.3533 14.1344H21.4795V0.546542ZM26.8332 6.60216C28.6735 6.60216 29.9573 5.76565 29.9573 4.16536V4.12897C29.9573 2.78691 28.8881 1.91403 26.935 1.91403H22.9961V6.59849L26.8332 6.60216ZM27.3751 12.7523C29.4336 12.7523 30.7138 11.8431 30.7138 10.3192V10.2828C30.7138 8.80616 29.47 7.95146 27.1023 7.95146H22.9961V12.7523L27.3751 12.7523ZM34.7109 4.09626H36.2094V14.1308H34.7109V4.09626ZM40.1664 10.9484V5.41649H37.8969V4.10717H40.1664V0.546542H41.6649V4.10717H45.7856V5.41649H41.6794V10.7556C41.6794 12.1049 42.3159 12.9633 43.5307 12.9378C44.2612 12.9522 44.988 12.8288 45.6729 12.5741V13.8798C44.884 14.204 44.0332 14.3493 43.1815 14.3053C41.3703 14.2653 40.181 12.9887 40.181 10.9338L40.1664 10.9484ZM47.9569 0.546542H51.1793L54.7508 6.28937L58.3187 0.546542H61.5411V14.1308H58.5769V5.26374L54.7544 11.0829H54.6744L50.892 5.34012V14.1417H47.9569V0.546542ZM64.1998 0.546542H74.4488V3.20519H67.1748V5.96204H73.2304V8.62069H67.1712V11.4721H74.5434V14.1308H64.1998V0.546542ZM80.1335 7.22044L75.5908 0.546542H79.086L81.9374 4.95096L84.8107 0.546542H88.2076L83.6723 7.18408L88.4004 14.1308H84.9089L81.8538 9.47903L78.7878 14.138H75.3908L80.1335 7.22044Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_8166">
          <rect
            className="rect"
            fill="white"
            height="14.7154"
            transform="translate(0.696289 0.019043)"
            width="88.1204"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
