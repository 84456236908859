/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoAceWhite8 = ({ className }) => {
  return (
    <svg
      className={`logo-ace-white-8 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 127 44"
      width="127"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_7232)">
        <path
          className="path-3"
          d="M27.3109 0.543457C27.4 0.8043 27.5782 1.01213 27.7203 1.23904C29.167 3.54634 30.62 5.84939 32.0752 8.15245C33.5197 10.4428 34.9685 12.731 36.4131 15.0213C37.8343 17.2735 39.2513 19.5278 40.6704 21.782C41.7544 23.5019 42.8426 25.2197 43.9265 26.9395C45.0953 28.793 46.2599 30.6465 47.4287 32.5C48.5126 34.2198 49.6008 35.9376 50.6848 37.6574C51.8217 39.4579 52.9587 41.2605 54.0936 43.0631C54.1381 43.133 54.1721 43.2073 54.2103 43.2815H24.653C24.706 43.133 24.793 42.9994 24.8715 42.8637C25.72 41.3962 26.5727 39.9308 27.4212 38.4633C28.3673 36.8261 29.3112 35.189 30.2552 33.5518C30.7388 32.7141 31.2182 31.8722 31.7061 31.0388C31.7909 30.8925 31.7782 30.7886 31.687 30.6528C31.4558 30.3114 31.2394 29.9594 31.0188 29.6095C29.8033 27.6797 28.5879 25.7498 27.3724 23.82C27.2663 23.6504 27.2388 23.6504 27.1306 23.82C26.6618 24.5623 26.193 25.3045 25.7242 26.0467C25.0496 27.1113 24.3751 28.1759 23.7027 29.2426C22.8139 30.6507 21.9293 32.0631 21.0405 33.4712C20.2917 34.6588 19.5408 35.8421 18.792 37.0297C17.8756 38.4824 16.9571 39.9351 16.045 41.3898C15.6505 42.0197 15.2368 42.6368 14.8698 43.2815C10.0631 43.2815 5.25641 43.2815 0.449697 43.2772C0.392423 43.2772 0.256664 43.3684 0.269392 43.1924C0.349999 43.1436 0.396666 43.0673 0.445454 42.991C1.25577 41.7101 2.06396 40.4271 2.87427 39.1462C3.89882 37.5217 4.92338 35.8973 5.95006 34.2728C6.87492 32.8075 7.80402 31.3442 8.72887 29.8767C9.83404 28.125 10.9371 26.3733 12.0422 24.6216C12.791 23.4341 13.542 22.2507 14.2907 21.0631C15.1965 19.6296 16.098 18.1939 17.0038 16.7582C17.7356 15.596 18.4696 14.436 19.2014 13.2739C20.0944 11.8573 20.9832 10.4407 21.8763 9.02617C22.6972 7.72407 23.5181 6.4241 24.339 5.122C25.3 3.59723 26.2588 2.07459 27.2176 0.549819C27.2472 0.549819 27.2769 0.549819 27.3066 0.549819L27.3109 0.543457Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M126.545 37.6938C125.314 37.6938 124.082 37.6959 122.851 37.6981C122.784 37.6981 122.716 37.7002 122.648 37.6981C122.504 37.6938 122.446 37.7553 122.463 37.9059C122.501 38.2261 122.552 38.5442 122.671 38.8475C123.115 39.9778 124.285 40.2429 125.198 39.7339C125.446 39.594 125.611 39.3692 125.741 39.1168C125.838 38.9302 126.201 38.8263 126.381 38.9323C126.483 38.9917 126.436 39.0786 126.409 39.1507C126.269 39.524 126.046 39.8399 125.736 40.0923C124.894 40.7752 123.471 40.7455 122.675 40.016C122.296 39.6661 122.071 39.2165 121.946 38.7202C121.702 37.7405 121.759 36.7862 122.202 35.87C122.921 34.3856 124.867 34.2901 125.813 35.1596C126.165 35.482 126.379 35.8934 126.496 36.3536C126.511 36.4108 126.525 36.4702 126.54 36.5274V37.6981L126.545 37.6938ZM124.224 37.1085C124.727 37.1085 125.229 37.1106 125.732 37.1085C125.925 37.1085 125.961 37.0619 125.94 36.8795C125.908 36.625 125.849 36.379 125.741 36.1436C125.308 35.1935 124.008 34.8945 123.201 35.5604C122.788 35.904 122.629 36.3726 122.535 36.8752C122.497 37.0852 122.514 37.1085 122.737 37.1085C123.231 37.1106 123.728 37.1085 124.222 37.1085H124.224Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M110.724 17.4074C110.724 14.2921 110.724 11.179 110.724 8.06371C110.724 7.98101 110.726 7.8983 110.724 7.81559C110.722 7.67351 110.79 7.60777 110.93 7.61201C110.998 7.61201 111.066 7.61201 111.134 7.61201C115.79 7.61201 120.448 7.61201 125.104 7.61201C125.377 7.61201 125.513 7.74773 125.513 8.01918C125.513 8.87381 125.513 9.73057 125.513 10.5852C125.513 10.8609 125.372 10.9987 125.089 10.9987C121.725 10.9987 118.358 10.9987 114.994 10.9987C114.74 10.9987 114.612 11.126 114.612 11.3805C114.612 12.7398 114.612 14.097 114.612 15.4564C114.612 15.6911 114.728 15.8084 114.958 15.8084C117.932 15.8084 120.906 15.8084 123.88 15.8084C124.154 15.8084 124.292 15.9463 124.292 16.222C124.292 17.1381 124.292 18.0521 124.292 18.9682C124.292 19.2694 124.275 19.2842 123.971 19.2842C121.012 19.2842 118.053 19.2842 115.094 19.2842C115.02 19.2842 114.943 19.2842 114.869 19.2842C114.631 19.2885 114.612 19.3033 114.612 19.5429C114.612 20.8641 114.612 22.1853 114.612 23.5065C114.612 23.8161 114.612 23.8161 114.928 23.8161C118.316 23.8161 121.704 23.8161 125.091 23.8161C125.373 23.8161 125.513 23.9547 125.513 24.2318C125.513 25.1331 125.513 26.0322 125.513 26.9335C125.513 27.2346 125.499 27.2495 125.193 27.2495C120.475 27.2495 115.76 27.2495 111.042 27.2495C110.739 27.2495 110.724 27.2346 110.724 26.9314C110.724 23.7567 110.724 20.5821 110.724 17.4074Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M82.9593 17.5557C82.9338 15.9037 83.2944 14.4362 83.9881 13.0535C84.4144 12.2052 84.949 11.4291 85.5875 10.7229C86.4593 9.75585 87.4839 8.99452 88.6378 8.41134C89.8639 7.7921 91.1642 7.4337 92.5409 7.32979C94.2612 7.20043 95.9073 7.4846 97.4897 8.15049C98.6203 8.62764 99.6322 9.29142 100.527 10.1354C101.202 10.7717 101.8 11.4715 102.273 12.2731C102.407 12.5 102.405 12.517 102.165 12.6124C101.104 13.0323 100.042 13.4479 98.981 13.8721C98.8261 13.9336 98.7349 13.8975 98.6394 13.7682C97.9691 12.852 97.144 12.1098 96.1385 11.5775C95.3261 11.147 94.4648 10.8883 93.5357 10.8565C91.8939 10.7992 90.4578 11.3336 89.2275 12.3897C87.8933 13.5349 87.0893 14.9897 86.8899 16.7477C86.6672 18.7051 87.2802 20.4017 88.5614 21.8734C89.5245 22.9804 90.7336 23.6908 92.1739 23.9686C93.8645 24.2952 95.4045 23.8986 96.813 22.9337C97.5279 22.446 98.1282 21.8416 98.6288 21.1396C98.737 20.987 98.8367 20.9658 99.0043 21.0336C100.056 21.4556 101.113 21.8692 102.167 22.2869C102.402 22.3802 102.409 22.4057 102.277 22.6284C101.295 24.2761 99.9313 25.5188 98.2428 26.418C97.0888 27.033 95.8585 27.4105 94.5624 27.5526C92.5791 27.771 90.67 27.4868 88.8605 26.6195C87.6896 26.0575 86.6396 25.328 85.7551 24.38C84.1896 22.7068 83.2308 20.7452 82.9974 18.4485C82.9635 18.1177 82.9635 17.789 82.9593 17.5578V17.5557Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M75.7982 27.2494C75.2042 27.2494 74.6103 27.2409 74.0184 27.2536C73.7915 27.26 73.6663 27.1879 73.5666 26.9801C73.153 26.1297 72.7181 25.2899 72.3024 24.4395C72.2112 24.2529 72.0987 24.1786 71.8866 24.1808C68.9508 24.1871 66.0129 24.1871 63.0771 24.1808C62.8586 24.1808 62.7377 24.2529 62.6444 24.4501C62.2414 25.2899 61.8192 26.1191 61.4183 26.9588C61.3186 27.1688 61.1913 27.2579 60.9516 27.2557C59.7574 27.2451 58.5631 27.2515 57.3689 27.2515C57.3095 27.2515 57.248 27.2515 57.1886 27.2451C57.0995 27.2367 57.0719 27.1858 57.1058 27.1031C57.1292 27.0479 57.1567 26.9949 57.1843 26.9398C58.2619 24.8785 59.3374 22.8172 60.4171 20.7537C60.9007 19.8291 61.3865 18.9066 61.868 17.982C62.9753 15.8613 64.0826 13.7385 65.192 11.6157C65.6926 10.6572 66.1932 9.70077 66.6938 8.74434C66.8529 8.43896 67.0184 8.13571 67.1732 7.82609C67.239 7.69248 67.3344 7.64795 67.4787 7.64795C67.6293 7.64795 67.7311 7.68824 67.8032 7.83033C68.1278 8.46441 68.4629 9.09425 68.7938 9.72621C69.619 11.3104 70.442 12.8945 71.2672 14.4787C71.9608 15.8083 72.6566 17.138 73.3524 18.4676C74.2348 20.1557 75.1172 21.8459 76.0018 23.534C76.5788 24.6367 77.1621 25.7395 77.7412 26.8401C77.7836 26.9207 77.8239 27.0013 77.8621 27.0818C77.913 27.1879 77.8685 27.2388 77.7603 27.243C77.7009 27.2451 77.6394 27.2451 77.58 27.2451C76.9861 27.2451 76.3942 27.2451 75.8003 27.2451L75.7982 27.2494ZM67.4511 20.8449C68.2996 20.8449 69.146 20.8449 69.9945 20.8449C70.2533 20.8449 70.2617 20.828 70.1472 20.5926C69.6869 19.6404 69.2245 18.6903 68.7663 17.7381C68.5414 17.2716 68.325 16.8029 68.1023 16.3364C67.9178 15.9504 67.729 15.5666 67.5444 15.1827C67.5041 15.0979 67.4681 15.0958 67.4256 15.1785C67.4087 15.2124 67.3917 15.2463 67.3769 15.2803C67.0629 15.9356 66.749 16.593 66.4329 17.2461C65.8856 18.3722 65.3384 19.4983 64.789 20.6223C64.6871 20.8322 64.6935 20.8449 64.9311 20.8449C65.7711 20.8449 66.6111 20.8449 67.4511 20.8449Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M117.126 37.4988C117.126 38.2856 117.137 39.0745 117.122 39.8613C117.113 40.3257 117.084 40.788 116.948 41.2397C116.666 42.1749 116.021 42.6712 115.073 42.7729C114.542 42.8302 114.006 42.8175 113.503 42.5863C112.935 42.3255 112.574 41.8886 112.434 41.2779C112.394 41.0997 112.423 41.0679 112.61 41.0594C112.684 41.0552 112.761 41.0616 112.835 41.0594C112.964 41.0531 113.043 41.104 113.091 41.2291C113.365 41.9226 113.915 42.1834 114.621 42.1898C114.772 42.1898 114.922 42.1919 115.071 42.1707C115.768 42.0625 116.199 41.6638 116.373 40.9746C116.479 40.5484 116.5 40.1157 116.492 39.6789C116.492 39.6428 116.505 39.594 116.462 39.5813C116.42 39.5686 116.405 39.6174 116.382 39.6449C115.378 40.8834 113.412 40.682 112.676 39.2357C112.075 38.0566 112.124 36.8456 112.773 35.7026C113.206 34.9413 113.921 34.5871 114.81 34.6105C115.143 34.6189 115.467 34.6592 115.773 34.8034C115.993 34.9074 116.188 35.041 116.35 35.2255C116.382 35.2615 116.401 35.34 116.462 35.3124C116.515 35.2912 116.49 35.2191 116.498 35.1682C116.509 35.1025 116.517 35.0346 116.526 34.9667C116.539 34.8713 116.53 34.7568 116.657 34.7356C116.797 34.7101 116.946 34.6826 117.071 34.7695C117.156 34.8268 117.128 34.9328 117.13 35.0219C117.13 35.8468 117.13 36.6718 117.13 37.4967H117.126V37.4988ZM116.492 37.467C116.511 37.1319 116.481 36.7672 116.392 36.4088C116.233 35.7662 115.851 35.3485 115.177 35.2424C115.037 35.2212 114.892 35.2233 114.75 35.217C113.993 35.183 113.442 35.6199 113.185 36.2731C112.886 37.0301 112.86 37.8084 113.115 38.5846C113.28 39.0872 113.588 39.4838 114.086 39.7064C114.413 39.8528 114.75 39.8613 115.1 39.8061C115.665 39.717 116.068 39.4286 116.284 38.8921C116.464 38.4446 116.505 37.9781 116.492 37.467Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M60.0227 40.4422C59.2951 40.4422 58.5654 40.4422 57.8378 40.4422C57.5663 40.4422 57.543 40.421 57.543 40.1453C57.543 37.7001 57.543 35.255 57.543 32.8077C57.543 32.5193 57.5599 32.5045 57.8506 32.5045C59.2845 32.5045 60.7185 32.5045 62.1524 32.5045C62.2521 32.5045 62.3857 32.4621 62.4303 32.5872C62.4854 32.7441 62.4854 32.9117 62.4218 33.0707C62.4006 33.1237 62.3391 33.1322 62.2861 33.1364C62.2118 33.1407 62.1354 33.1428 62.0612 33.1428C60.8818 33.1428 59.7045 33.1428 58.5251 33.1428C58.2472 33.1428 58.2281 33.1598 58.2281 33.4355C58.2281 34.1713 58.2281 34.9051 58.2281 35.641C58.2281 35.9145 58.2451 35.9273 58.5272 35.9294C59.6451 35.9294 60.7651 35.9294 61.883 35.9315C61.9954 35.9315 62.1482 35.8785 62.2097 36.0121C62.2754 36.1563 62.2521 36.3217 62.2203 36.4765C62.2054 36.5444 62.1418 36.5613 62.0782 36.5656C62.0039 36.5698 61.9276 36.5698 61.8533 36.5698C60.7503 36.5698 59.6451 36.5698 58.5421 36.5698C58.2366 36.5698 58.2281 36.5783 58.2281 36.8879C58.2281 37.7574 58.2281 38.629 58.2281 39.4985C58.2281 39.789 58.2409 39.8017 58.5336 39.8017C59.7575 39.8017 60.9815 39.8017 62.2054 39.8017C62.2797 39.8017 62.3561 39.8017 62.4303 39.806C62.4748 39.806 62.5279 39.8081 62.5554 39.8484C62.6573 40.0011 62.5576 40.1516 62.5448 40.3043C62.5364 40.4019 62.4557 40.4358 62.3645 40.4401C62.2967 40.4422 62.2288 40.4443 62.1609 40.4443C61.4482 40.4443 60.7354 40.4443 60.0206 40.4443L60.0227 40.4422Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M98.0221 38.0753C98.0221 38.5249 98.0221 38.9766 98.0221 39.4262C98.0221 39.651 98.0264 39.8758 98.0582 40.1006C98.1006 40.4059 98.0582 40.4441 97.7591 40.4441C97.7294 40.4441 97.6997 40.442 97.67 40.4441C97.5321 40.4483 97.4685 40.3741 97.443 40.2469C97.4112 40.0963 97.443 39.933 97.3476 39.7973C97.2415 39.9012 97.1397 40.0094 97.0294 40.109C96.7091 40.3996 96.3166 40.5077 95.8988 40.5607C95.4321 40.6201 94.9824 40.5671 94.5603 40.3593C93.9515 40.0603 93.6121 39.3774 93.6693 38.6988C93.7372 37.9035 94.1827 37.4497 94.9039 37.2101C95.2836 37.0828 95.6803 37.0319 96.0791 37.0213C96.4397 37.0107 96.8003 37.0128 97.1609 37.0192C97.3242 37.0213 97.3942 36.9556 97.3879 36.7923C97.3794 36.5823 97.3921 36.3724 97.3603 36.1646C97.2797 35.6556 96.9785 35.363 96.4863 35.259C96.13 35.1827 95.7715 35.1869 95.4151 35.2696C95.0481 35.3524 94.8042 35.5687 94.6727 35.9228C94.5369 36.2833 94.5136 36.2897 94.1339 36.2642C93.979 36.2536 93.9366 36.1837 93.9663 36.0458C94.0787 35.539 94.3227 35.1276 94.7957 34.8731C95.1775 34.6695 95.5912 34.608 96.0154 34.6165C96.3294 34.6228 96.6433 34.644 96.9488 34.748C97.5406 34.9494 97.8715 35.3672 97.9818 35.9695C98.0157 36.154 98.0306 36.3406 98.0285 36.5293C98.0242 37.0468 98.0285 37.5642 98.0285 38.0838L98.0221 38.0753ZM96.603 37.5663C96.446 37.5663 96.2869 37.5579 96.13 37.5663C95.7481 37.5897 95.3663 37.6236 95.0078 37.7805C94.5348 37.9884 94.2972 38.3913 94.3333 38.9172C94.3693 39.4135 94.6769 39.7867 95.1606 39.9224C95.5254 40.0242 95.8903 39.9924 96.2509 39.9012C96.7579 39.7718 97.0867 39.4516 97.2415 38.9511C97.3624 38.5588 97.39 38.1559 97.3815 37.7487C97.3794 37.6151 97.3136 37.56 97.1842 37.5621C96.9891 37.5642 96.7939 37.5621 96.5988 37.5621L96.603 37.5663Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M84.5864 36.26C84.5864 34.9918 84.5864 33.7258 84.5864 32.4576C84.5864 32.0822 84.7137 31.9847 85.0849 32.0695C85.1698 32.0886 85.2186 32.1331 85.2207 32.2222C85.2228 32.2901 85.2249 32.3579 85.2249 32.4237C85.2249 33.3546 85.2249 34.2835 85.2249 35.2145C85.2249 35.2675 85.2207 35.3205 85.2249 35.3714C85.2292 35.4117 85.2016 35.4732 85.2589 35.488C85.2949 35.4986 85.314 35.4456 85.3373 35.4159C85.9016 34.6822 86.6652 34.4998 87.5286 34.6589C88.341 34.8094 88.8628 35.452 88.9625 36.349C88.9922 36.6184 89.0071 36.8856 89.0071 37.157C89.0028 38.1474 89.0071 39.1377 89.005 40.1281C89.005 40.2299 89.0368 40.3614 88.918 40.408C88.7631 40.4695 88.5934 40.4631 88.4343 40.408C88.3792 40.3889 88.3728 40.3274 88.3707 40.2744C88.3686 40.2065 88.3665 40.1387 88.3665 40.0729C88.3665 39.0529 88.3665 38.0329 88.3665 37.0128C88.3665 36.7414 88.3325 36.4742 88.2604 36.2133C88.0843 35.5877 87.6474 35.2357 86.994 35.2314C86.1392 35.2251 85.5176 35.5792 85.3055 36.5547C85.2525 36.7965 85.227 37.0425 85.227 37.2906C85.2292 38.228 85.2292 39.1653 85.227 40.1026C85.227 40.2087 85.2758 40.3529 85.1528 40.4101C85.0022 40.478 84.8304 40.461 84.6692 40.4165C84.6119 40.4016 84.5991 40.3423 84.5949 40.2871C84.5907 40.2129 84.5885 40.1366 84.5885 40.0623C84.5885 38.7942 84.5885 37.5281 84.5885 36.26H84.5864Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M103.192 37.696C103.192 37.0216 103.192 36.3451 103.192 35.6707C103.192 35.4459 103.198 35.2212 103.168 34.9964C103.141 34.7822 103.196 34.7313 103.414 34.7228C103.467 34.7228 103.52 34.7228 103.571 34.7228C103.715 34.7228 103.792 34.7864 103.798 34.937C103.807 35.1024 103.841 35.2636 103.834 35.4311C103.834 35.465 103.822 35.518 103.862 35.5287C103.904 35.5414 103.917 35.4884 103.936 35.4587C104.218 35.0154 104.615 34.7398 105.132 34.6549C105.644 34.5701 106.151 34.5743 106.626 34.8288C106.984 35.0197 107.243 35.3039 107.389 35.6813C107.512 35.9973 107.578 36.3281 107.578 36.6717C107.576 37.819 107.578 38.9684 107.576 40.1157C107.576 40.2217 107.623 40.3659 107.489 40.4147C107.341 40.4698 107.179 40.4571 107.025 40.4168C106.967 40.402 106.95 40.3468 106.946 40.2917C106.942 40.2175 106.94 40.1411 106.94 40.0669C106.94 38.9938 106.94 37.9208 106.94 36.8477C106.94 36.6526 106.927 36.4596 106.885 36.2666C106.711 35.465 106.149 35.1724 105.408 35.236C104.674 35.2975 104.214 35.7089 103.989 36.3981C103.894 36.6929 103.832 36.9919 103.834 37.3037C103.836 38.2198 103.834 39.1338 103.834 40.0499C103.834 40.4274 103.709 40.5228 103.334 40.4359C103.249 40.4168 103.2 40.3723 103.198 40.2832C103.196 40.209 103.194 40.1326 103.194 40.0584C103.194 39.2695 103.194 38.4828 103.194 37.6939L103.192 37.696Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M77.966 34.6123C78.9099 34.6357 79.6354 35.168 79.9132 36.1159C79.9302 36.1732 79.9429 36.2325 79.9536 36.2919C79.9769 36.417 79.9239 36.4764 79.7987 36.4785C79.7245 36.4785 79.6481 36.4764 79.5739 36.4807C79.4148 36.4913 79.3299 36.4213 79.279 36.2686C79.156 35.9038 78.9523 35.5921 78.6023 35.4033C77.7517 34.941 76.7017 35.2782 76.2838 36.188C75.8426 37.1465 75.8511 38.1327 76.3262 39.0785C76.8375 40.0985 77.9978 40.1728 78.6893 39.6998C78.9566 39.5175 79.1029 39.2524 79.2387 38.9724C79.2832 38.8813 79.296 38.754 79.419 38.7307C79.5738 38.7031 79.7351 38.6989 79.8899 38.7328C79.9811 38.7519 79.9578 38.8388 79.9366 38.9025C79.6672 39.721 79.1835 40.3466 78.265 40.5227C76.9626 40.7729 75.8893 40.1388 75.499 38.8728C75.1574 37.7637 75.219 36.6715 75.8468 35.6663C76.3029 34.9326 76.9987 34.5975 77.966 34.6102V34.6123Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M71.1165 40.4442C70.9002 40.4951 70.7559 40.3699 70.6223 40.1664C70.1853 39.5026 69.7314 38.8494 69.2817 38.192C69.165 38.0202 69.1586 38.0224 69.0398 38.1963C68.5859 38.8685 68.1341 39.5408 67.6801 40.213C67.5444 40.4145 67.1392 40.5332 66.925 40.4293C66.8125 40.3742 66.8804 40.3042 66.9207 40.2448C67.1625 39.8928 67.4086 39.5429 67.6525 39.1909C68.0047 38.6861 68.3547 38.1793 68.711 37.6788C68.7959 37.5601 68.7598 37.4752 68.6898 37.3756C68.2847 36.7902 67.8795 36.2028 67.4765 35.6154C67.3195 35.3864 67.1604 35.1594 67.0077 34.9283C66.9165 34.7926 66.9398 34.7396 67.0989 34.7268C67.2262 34.7162 67.3535 34.7226 67.4807 34.7268C67.6186 34.7311 67.6886 34.8307 67.7565 34.9325C68.168 35.5496 68.5817 36.1689 68.9953 36.786C69.0207 36.8242 69.0441 36.8623 69.0695 36.8984C69.1735 37.0405 69.1926 37.0426 69.2901 36.9026C69.6062 36.4552 69.9201 36.0056 70.2341 35.5581C70.3762 35.3545 70.5162 35.151 70.6583 34.9495C70.8026 34.7459 71.1717 34.642 71.4029 34.7374C71.5217 34.7862 71.458 34.8583 71.4177 34.9156C71.2629 35.1361 71.1059 35.3567 70.9468 35.5751C70.5332 36.1477 70.1238 36.7203 69.7038 37.2865C69.5935 37.4349 69.585 37.5537 69.6932 37.7085C70.2468 38.5016 70.792 39.3032 71.3393 40.1006C71.352 40.1197 71.3668 40.1367 71.3774 40.1558C71.4156 40.2321 71.5238 40.2978 71.4687 40.3912C71.4156 40.4781 71.3032 40.4315 71.2162 40.4421C71.195 40.4442 71.1717 40.4421 71.1123 40.4421L71.1165 40.4442Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_7232">
          <rect
            className="rect"
            fill="white"
            height="42.7529"
            transform="translate(0.271484 0.543457)"
            width="126.273"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
