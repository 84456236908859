/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite1 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 104 21"
      width="104"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_10651)">
        <path
          className="path-3"
          d="M7.10067 8.77628L11.0729 4.80575L15.0467 8.77951L17.3567 6.46794L11.0729 0.182617L4.78906 6.46633L7.10067 8.77628Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.844727 10.4092L3.15548 8.09846L5.46624 10.4092L3.15548 12.7199L0.844727 10.4092Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M7.09999 12.0412L11.0722 16.0133L15.046 12.0396L17.3576 14.3479L17.356 14.3495L11.0722 20.6348L4.78839 14.3527L4.78516 14.3495L7.09999 12.0412Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M16.6748 10.4097L18.9856 8.09895L21.2963 10.4097L18.9856 12.7204L16.6748 10.4097Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M13.4166 10.408L11.0727 8.0625L9.33943 9.79577L9.13912 9.99446L8.72882 10.4048L8.72559 10.408L8.72882 10.4128L11.0727 12.7551L13.4166 10.4096L13.4183 10.408H13.4166Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M24.9023 5.13818H29.9294C31.1765 5.13818 32.1198 5.46125 32.7595 6.10739C33.0005 6.34607 33.1905 6.63123 33.318 6.94556C33.4454 7.2599 33.5077 7.59685 33.501 7.93597V7.96666C33.5053 8.24447 33.4666 8.52128 33.3863 8.78726C33.3147 9.01623 33.2108 9.2338 33.0778 9.4334C32.9537 9.62161 32.8036 9.7913 32.6319 9.93739C32.4642 10.0818 32.2804 10.2064 32.0843 10.3089C32.6499 10.5059 33.1586 10.8387 33.5656 11.2781C33.9253 11.6874 34.1051 12.2527 34.1051 12.9742V13.0049C34.1154 13.4556 34.0173 13.902 33.8192 14.3069C33.6273 14.6799 33.3459 14.9996 33.0002 15.2374C32.6094 15.4993 32.1743 15.6882 31.716 15.7946C31.1715 15.9238 30.6133 15.9861 30.0538 15.9804H24.9023V5.13818ZM29.4254 9.52224C29.8603 9.54326 30.2931 9.44968 30.6805 9.25086C30.8316 9.16262 30.955 9.03384 31.0366 8.8791C31.1183 8.72437 31.155 8.54986 31.1425 8.37535V8.34465C31.1505 8.18385 31.1179 8.02363 31.0476 7.8788C30.9773 7.73396 30.8716 7.60918 30.7403 7.51598C30.4722 7.32537 30.085 7.23006 29.5789 7.23006H27.2269V9.52224H29.4254ZM30.0602 13.8918C30.4936 13.916 30.9249 13.8169 31.3041 13.6058C31.4518 13.5109 31.5714 13.3782 31.6504 13.2213C31.7294 13.0645 31.7648 12.8894 31.7532 12.7142V12.6835C31.759 12.517 31.7239 12.3515 31.6509 12.2017C31.578 12.0519 31.4694 11.9223 31.3348 11.8241C31.0569 11.6087 30.6079 11.501 29.9875 11.501H27.2269V13.8918H30.0602Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M37.0996 5.13818H39.4855V15.9836H37.0996V5.13818Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M43.0967 5.13818H45.2968L50.382 11.8128V5.13818H52.7372V15.9836H50.7067L45.4551 9.08933V15.9836H43.0967V5.13818Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M59.9606 5.06104H62.1575L66.8049 15.984H64.314L63.3222 13.5513H58.7361L57.7443 15.984H55.3115L59.9606 5.06104ZM62.4709 11.4449L61.0267 7.92667L59.589 11.4449H62.4709Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M69.3867 5.14502H71.5869L76.6688 11.8196V5.14502H79.0241V15.9905H76.9935L71.7419 9.09617V15.9905H69.3867V5.14502Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M87.6095 16.1745C86.8491 16.181 86.0952 16.0337 85.3932 15.7416C84.7322 15.4687 84.1326 15.0662 83.6296 14.558C83.1266 14.0498 82.7305 13.446 82.4645 12.7822C82.1838 12.088 82.0417 11.3455 82.0461 10.5967V10.566C82.0336 9.08992 82.6016 7.66804 83.6276 6.60676C84.1324 6.08836 84.7368 5.67736 85.4045 5.39848C86.1307 5.09764 86.9106 4.94761 87.6967 4.95749C88.1616 4.95402 88.6258 4.99568 89.0827 5.08187C89.4736 5.1558 89.8558 5.27002 90.2231 5.42271C90.5565 5.56303 90.8734 5.73963 91.1681 5.94931C91.4557 6.15383 91.7259 6.38183 91.9758 6.63099L90.459 8.38203C90.0714 8.02445 89.6365 7.72172 89.1667 7.48228C88.7051 7.26157 88.1986 7.15098 87.687 7.15921C87.2612 7.15467 86.8394 7.24076 86.4495 7.41176C86.0596 7.58277 85.7105 7.83477 85.4255 8.15104C85.1428 8.46649 84.9215 8.83189 84.7729 9.22848C84.6172 9.64758 84.539 10.0915 84.5419 10.5385V10.5692C84.5403 11.0156 84.6186 11.4587 84.7729 11.8777C84.9196 12.2757 85.1387 12.6431 85.419 12.9616C85.7018 13.2817 86.05 13.5374 86.4402 13.7114C86.8304 13.8853 87.2534 13.9733 87.6805 13.9695C88.2245 13.9867 88.7643 13.8696 89.2523 13.6287C89.7169 13.3755 90.1465 13.0627 90.5301 12.6982L92.0485 14.2328C91.7804 14.5238 91.4904 14.7938 91.181 15.0405C90.8771 15.2808 90.5471 15.4862 90.1973 15.6527C89.8245 15.8282 89.4318 15.9578 89.0278 16.0388C88.5609 16.1324 88.0856 16.1779 87.6095 16.1745Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M94.8643 5.13818H103.027V7.26075H97.2114V9.46086H102.332V11.5834H97.2195V13.8611H103.108V15.9836H94.8691L94.8643 5.13818Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_10651">
          <rect
            className="rect"
            fill="white"
            height="20.452"
            transform="translate(0.845703 0.182617)"
            width="102.26"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
