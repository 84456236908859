/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite4 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-4 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 121 21"
      width="121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-3"
        d="M5.76351 1.22266L1.1543 19.7041H5.77342L8.6548 8.14886H13.2789L15.0067 1.22266H10.3826H5.76351Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M24.2449 1.22266H19.6258L17.898 8.14886L16.7444 12.7729H12.1253L10.3975 19.7041H15.0166H19.6357L22.5171 8.14886L24.2449 1.22266Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M49.1773 2.34184C49.1809 2.51326 49.1474 2.68344 49.079 2.84069C49.0106 2.99794 48.9091 3.13852 48.7812 3.25279C48.5488 3.48138 48.2359 3.60947 47.9099 3.60947C47.5839 3.60947 47.271 3.48138 47.0385 3.25279C46.8011 3.02505 46.6628 2.71304 46.6536 2.38417C46.6443 2.05531 46.7648 1.73601 46.989 1.49525L47.0484 1.4309C47.2808 1.20232 47.5938 1.07422 47.9197 1.07422C48.2457 1.07422 48.5587 1.20232 48.7911 1.4309C48.919 1.54459 49.02 1.68531 49.0868 1.84287C49.1536 2.00043 49.1845 2.17086 49.1773 2.34184ZM28.8789 1.218H36.523C38.637 1.218 40.2955 1.82695 41.3649 2.85672C41.7522 3.24675 42.057 3.71077 42.2611 4.22111C42.4653 4.73146 42.5646 5.27768 42.5531 5.82722V5.8817C42.5531 8.2878 41.0678 9.53046 39.6222 10.1592C41.8154 10.8226 43.5829 12.09 43.5829 14.6149V14.6694C43.5829 17.838 40.9193 19.7143 36.8745 19.7143H28.8789V1.218ZM36.1665 9.46113C38.6716 9.46113 40.4193 8.32243 40.4193 6.14407V6.09453C40.4193 4.26767 38.9637 3.07947 36.3051 3.07947H30.9434V9.45613L36.1665 9.46113ZM36.9042 17.833C39.7064 17.833 41.4491 16.5953 41.4491 14.5209V14.4713C41.4491 12.4613 39.7559 11.2978 36.5329 11.2978H30.9434V17.8329L36.9042 17.833ZM46.89 6.05H48.9297V19.7093H46.89V6.05ZM54.3162 15.3774V7.84715H51.2269V6.06486H54.3162V1.218H56.356V6.06486H61.9653V7.84715H56.3758V15.115C56.3758 16.9517 57.2422 18.1201 58.8958 18.0855C59.8902 18.105 60.8795 17.937 61.8118 17.5904V19.3677C60.738 19.809 59.5798 20.0068 58.4205 19.947C55.955 19.8925 54.336 18.1548 54.336 15.3576L54.3162 15.3774ZM64.9209 1.218H69.3073L74.1691 9.03535L79.0258 1.218H83.4122V19.7093H79.3773V7.63922L74.174 15.5605H74.0651L68.9162 7.74319V19.7242H64.9209V1.218ZM87.0313 1.218H100.983V4.83705H91.0811V8.58978H99.3242V12.2088H91.0761V16.0903H101.111V19.7093H87.0313V1.218ZM108.721 10.3028L102.537 1.218H107.295L111.176 7.21345L115.088 1.218H119.712L113.538 10.2533L119.974 19.7093H115.221L111.063 13.3772L106.889 19.7192H102.265L108.721 10.3028Z"
        fill="white"
      />
    </svg>
  );
};
