/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite8 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-8 ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 177 30"
      width="177"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_6272)">
        <path
          className="path-3"
          d="M8.24972 1.08057L1.4917 28.178H8.26424L12.4889 11.2357H19.2687L21.8021 1.08057H15.0223H8.24972Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M35.3471 1.08057H28.5745L26.0412 11.2357L24.3498 18.0155H17.5773L15.0439 28.178H21.8165H28.589L32.8137 11.2357L35.3471 1.08057Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M71.903 2.72137C71.9084 2.97272 71.8592 3.22224 71.759 3.45279C71.6587 3.68334 71.5098 3.88947 71.3223 4.05701C70.9816 4.39216 70.5227 4.57998 70.0448 4.57998C69.5668 4.57998 69.108 4.39216 68.7672 4.05701C68.4191 3.72309 68.2163 3.26562 68.2028 2.78345C68.1892 2.30127 68.3658 1.83311 68.6946 1.48011L68.7817 1.38576C69.1224 1.05061 69.5813 0.862793 70.0592 0.862793C70.5372 0.862793 70.996 1.05061 71.3368 1.38576C71.5243 1.55245 71.6724 1.75878 71.7704 1.98979C71.8683 2.2208 71.9137 2.47069 71.903 2.72137ZM42.1416 1.0736H53.3493C56.4489 1.0736 58.8806 1.96645 60.4485 3.4763C61.0164 4.04815 61.4633 4.72849 61.7626 5.47676C62.0619 6.22503 62.2075 7.0259 62.1906 7.83163V7.91151C62.1906 11.4393 60.013 13.2613 57.8934 14.1832C61.1091 15.1559 63.7005 17.0141 63.7005 20.7162V20.7961C63.7005 25.4417 59.7952 28.1929 53.8647 28.1929H42.1416V1.0736ZM52.8267 13.1597C56.4997 13.1597 59.0621 11.4901 59.0621 8.29619V8.22355C59.0621 5.54502 56.928 3.80289 53.0299 3.80289H45.1686V13.1523L52.8267 13.1597ZM53.9083 25.4344C58.0168 25.4344 60.5719 23.6197 60.5719 20.5782V20.5056C60.5719 17.5585 58.0894 15.8526 53.3638 15.8526H45.1686V25.4344L53.9083 25.4344ZM68.5494 8.15827H71.5401V28.1856H68.5494V8.15827ZM79.4378 21.834V10.7933H74.9082V8.18005H79.4378V1.0736H82.4284V8.18005H90.6527V10.7933H82.4575V21.4493C82.4575 24.1423 83.7278 25.8554 86.1522 25.8046C87.6103 25.8332 89.0608 25.587 90.4277 25.0787V27.6847C88.8534 28.3316 87.1552 28.6217 85.4554 28.534C81.8405 28.4541 79.4668 25.9063 79.4668 21.805L79.4378 21.834ZM94.9863 1.0736H101.418L108.546 12.5354L115.667 1.0736H122.098V28.1856H116.182V10.4884L108.553 22.1026H108.393L100.844 10.6408V28.2073H94.9863V1.0736ZM127.404 1.0736H147.86V6.37985H133.342V11.8821H145.428V17.1883H133.335V22.8793H148.049V28.1856H127.404V1.0736ZM159.206 14.3937L150.139 1.0736H157.115L162.806 9.86411L168.541 1.0736H175.32L166.269 14.3211L175.705 28.1856H168.737L162.639 18.9014L156.52 28.2001H149.74L159.206 14.3937Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_6272">
          <rect
            className="rect"
            fill="white"
            height="29.3697"
            transform="translate(0.661133 0.0209961)"
            width="175.874"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
