/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite4 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-4 ${className}`}
      fill="none"
      height="33"
      viewBox="0 0 159 33"
      width="159"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_8643)">
        <path
          className="path-3"
          d="M10.138 14.1853L16.2937 8.03216L22.452 14.1903L26.0318 10.6081L16.2937 0.867676L6.55566 10.6056L10.138 14.1853Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.443359 16.7153L4.02435 13.1344L7.60534 16.7153L4.02435 20.2963L0.443359 16.7153Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M10.1381 19.2447L16.2938 25.4003L22.4521 19.2422L26.0344 22.8194L26.0319 22.8219L16.2938 32.5623L6.55579 22.8269L6.55078 22.8219L10.1381 19.2447Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M24.9756 16.7163L28.5566 13.1354L32.1376 16.7163L28.5566 20.2972L24.9756 16.7163Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M19.926 16.7134L16.2936 13.0786L13.6075 15.7647L13.2971 16.0726L12.6613 16.7084L12.6562 16.7134L12.6613 16.7209L16.2936 20.3507L19.926 16.7159L19.9285 16.7134H19.926Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M37.7246 8.54785H45.515C47.4476 8.54785 48.9096 9.04851 49.9009 10.0498C50.2744 10.4197 50.5688 10.8616 50.7664 11.3488C50.9639 11.8359 51.0604 12.3581 51.05 12.8836V12.9312C51.0567 13.3617 50.9967 13.7907 50.8722 14.2028C50.7613 14.5577 50.6003 14.8948 50.3941 15.2042C50.2018 15.4958 49.9692 15.7588 49.7032 15.9852C49.4432 16.2089 49.1585 16.4021 48.8545 16.561C49.7311 16.8663 50.5193 17.382 51.1501 18.0629C51.7075 18.6971 51.9862 19.5733 51.9862 20.6914V20.739C52.0021 21.4373 51.8501 22.1292 51.5431 22.7567C51.2457 23.3348 50.8096 23.8302 50.2739 24.1986C49.6683 24.6046 48.994 24.8972 48.2838 25.0622C47.44 25.2623 46.575 25.359 45.7078 25.3501H37.7246V8.54785ZM44.734 15.3418C45.408 15.3744 46.0788 15.2294 46.6791 14.9213C46.9132 14.7845 47.1044 14.585 47.2309 14.3452C47.3575 14.1054 47.4143 13.8349 47.3951 13.5645V13.5169C47.4075 13.2677 47.3568 13.0194 47.2479 12.795C47.1389 12.5705 46.9752 12.3772 46.7717 12.2327C46.3562 11.9373 45.7562 11.7896 44.9718 11.7896H41.3269V15.3418H44.734ZM45.7178 22.1133C46.3893 22.1509 47.0577 21.9973 47.6454 21.6702C47.8743 21.5231 48.0597 21.3174 48.1821 21.0743C48.3044 20.8313 48.3595 20.5599 48.3413 20.2884V20.2408C48.3504 19.9828 48.296 19.7264 48.1829 19.4942C48.0699 19.2621 47.9017 19.0611 47.693 18.9091C47.2624 18.5753 46.5665 18.4084 45.6052 18.4084H41.3269V22.1133H45.7178Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M56.6279 8.54785H60.3254V25.3551H56.6279V8.54785Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M65.9199 8.54785H69.3295L77.21 18.8915V8.54785H80.8599V25.3551H77.7132L69.5748 14.671V25.3551H65.9199V8.54785Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M92.0552 8.42773H95.4598L102.662 25.3551H98.8018L97.2647 21.5852H90.1577L88.6206 25.3551H84.8506L92.0552 8.42773ZM95.9454 18.3208L93.7075 12.8686L91.4795 18.3208H95.9454Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M106.662 8.55811H110.072L117.947 18.9018V8.55811H121.597V25.3654H118.45L110.312 14.6812V25.3654H106.662V8.55811Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M134.902 25.6503C133.724 25.6604 132.556 25.4322 131.468 24.9794C130.443 24.5565 129.514 23.9329 128.735 23.1452C127.955 22.3576 127.341 21.4219 126.929 20.3934C126.494 19.3175 126.274 18.1669 126.281 17.0064V16.9588C126.261 14.6714 127.141 12.4679 128.731 10.8232C129.514 10.0198 130.45 9.3829 131.485 8.95072C132.611 8.48451 133.819 8.25199 135.037 8.26731C135.758 8.26194 136.477 8.32649 137.185 8.46007C137.791 8.57463 138.383 8.75164 138.953 8.98827C139.469 9.20571 139.96 9.47939 140.417 9.80435C140.863 10.1213 141.281 10.4746 141.669 10.8607L139.318 13.5743C138.717 13.0202 138.044 12.551 137.315 12.18C136.6 11.8379 135.815 11.6666 135.022 11.6793C134.363 11.6723 133.709 11.8057 133.105 12.0707C132.5 12.3357 131.959 12.7262 131.518 13.2164C131.08 13.7052 130.737 14.2715 130.506 14.8861C130.265 15.5356 130.144 16.2234 130.148 16.9163V16.9638C130.146 17.6556 130.267 18.3423 130.506 18.9915C130.734 19.6083 131.073 20.1778 131.508 20.6712C131.946 21.1674 132.485 21.5637 133.09 21.8332C133.695 22.1027 134.35 22.2391 135.012 22.2333C135.855 22.2599 136.692 22.0784 137.448 21.7051C138.168 21.3127 138.834 20.828 139.428 20.2632L141.781 22.6413C141.366 23.0923 140.916 23.5107 140.437 23.893C139.966 24.2654 139.455 24.5836 138.913 24.8418C138.335 25.1137 137.726 25.3146 137.1 25.44C136.377 25.5851 135.64 25.6556 134.902 25.6503Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M146.145 8.54785H158.794V11.8372H149.782V15.2467H157.718V18.5361H149.794V22.0657H158.919V25.3551H146.152L146.145 8.54785Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_8643">
          <rect
            className="rect"
            fill="white"
            height="31.6945"
            transform="translate(0.445312 0.867676)"
            width="158.472"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
