/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite5 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-5 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 119 24"
      width="119"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_10206)">
        <path
          className="path-3"
          d="M7.35009 10.1986L11.9389 5.61172L16.5295 10.2023L19.198 7.53192L11.9389 0.270996L4.67969 7.53005L7.35009 10.1986Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.124023 12.0845L2.79345 9.41509L5.46288 12.0845L2.79345 14.7539L0.124023 12.0845Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M7.3509 13.9701L11.9397 18.5588L16.5303 13.9683L19.2007 16.6349L19.1988 16.6368L11.9397 23.8977L4.68049 16.6405L4.67676 16.6368L7.3509 13.9701Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M18.4111 12.0854L21.0806 9.41606L23.75 12.0854L21.0806 14.7548L18.4111 12.0854Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M14.6467 12.0831L11.939 9.37354L9.93666 11.3758L9.70526 11.6054L9.23127 12.0794L9.22754 12.0831L9.23127 12.0887L11.939 14.7945L14.6467 12.085L14.6486 12.0831H14.6467Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M27.915 5.99609H33.7224C35.163 5.99609 36.2528 6.36931 36.9918 7.11574C37.2702 7.39147 37.4897 7.72089 37.6369 8.08401C37.7842 8.44713 37.8561 8.83639 37.8484 9.22815V9.2636C37.8534 9.58453 37.8087 9.90431 37.7159 10.2116C37.6332 10.4761 37.5132 10.7274 37.3594 10.958C37.2161 11.1754 37.0427 11.3715 36.8444 11.5402C36.6506 11.707 36.4384 11.851 36.2118 11.9694C36.8652 12.197 37.4528 12.5815 37.923 13.0891C38.3385 13.5618 38.5463 14.2149 38.5463 15.0485V15.0839C38.5581 15.6045 38.4448 16.1203 38.216 16.588C37.9942 17.0189 37.6692 17.3882 37.2699 17.6628C36.8184 17.9655 36.3157 18.1836 35.7863 18.3066C35.1573 18.4558 34.5125 18.5279 33.8661 18.5212H27.915V5.99609ZM33.1402 11.0606C33.6426 11.0849 34.1426 10.9768 34.5901 10.7471C34.7646 10.6452 34.9071 10.4964 35.0015 10.3177C35.0958 10.1389 35.1382 9.93732 35.1238 9.73572V9.70027C35.1331 9.51451 35.0953 9.32942 35.0141 9.1621C34.9329 8.99478 34.8108 8.85063 34.6592 8.74297C34.3494 8.52277 33.9021 8.41267 33.3174 8.41267H30.6004V11.0606H33.1402ZM33.8735 16.1084C34.3741 16.1364 34.8724 16.0219 35.3104 15.7781C35.4811 15.6684 35.6192 15.5151 35.7105 15.3339C35.8017 15.1527 35.8427 14.9504 35.8292 14.748V14.7126C35.8359 14.5202 35.7954 14.3291 35.7111 14.156C35.6269 13.9829 35.5015 13.8332 35.3459 13.7198C35.0249 13.471 34.5061 13.3466 33.7896 13.3466H30.6004V16.1084H33.8735Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M42.0059 5.99609H44.7621V18.525H42.0059V5.99609Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M48.9326 5.99609H51.4743L57.3488 13.7067V5.99609H60.0696V18.525H57.7239L51.6571 10.5605V18.525H48.9326V5.99609Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M68.4156 5.90674H70.9535L76.3223 18.5252H73.4448L72.299 15.7149H67.0011L65.8553 18.5252H63.0449L68.4156 5.90674ZM71.3155 13.2815L69.6472 9.21717L67.9864 13.2815H71.3155Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M79.3047 6.00391H81.8463L87.7171 13.7146V6.00391H90.4379V18.5328H88.0922L82.0255 10.5683V18.5328H79.3047V6.00391Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M100.356 18.7452C99.4774 18.7527 98.6065 18.5826 97.7955 18.2451C97.032 17.9299 96.3393 17.465 95.7583 16.8778C95.1772 16.2907 94.7196 15.5932 94.4123 14.8265C94.088 14.0244 93.9238 13.1667 93.9289 12.3016V12.2662C93.9145 10.561 94.5706 8.91843 95.7559 7.69242C96.339 7.09355 97.0373 6.61876 97.8086 6.29659C98.6476 5.94906 99.5485 5.77573 100.457 5.78715C100.994 5.78314 101.53 5.83126 102.058 5.93084C102.509 6.01624 102.951 6.14819 103.375 6.32458C103.76 6.48668 104.126 6.69069 104.467 6.93293C104.799 7.16918 105.111 7.43258 105.4 7.72041L103.648 9.74324C103.2 9.33016 102.698 8.98044 102.155 8.70384C101.622 8.44886 101.036 8.32111 100.445 8.33062C99.9536 8.32537 99.4663 8.42482 99.0158 8.62237C98.5654 8.81992 98.1621 9.11104 97.8328 9.47639C97.5064 9.84082 97.2507 10.2629 97.0789 10.7211C96.8991 11.2052 96.8087 11.718 96.8121 12.2345V12.2699C96.8103 12.7856 96.9007 13.2975 97.0789 13.7814C97.2485 14.2412 97.5016 14.6657 97.8254 15.0336C98.152 15.4035 98.5543 15.6989 99.0051 15.8998C99.4558 16.1007 99.9445 16.2024 100.438 16.198C101.066 16.2178 101.69 16.0826 102.254 15.8043C102.79 15.5118 103.287 15.1504 103.73 14.7294L105.484 16.5022C105.174 16.8384 104.839 17.1503 104.482 17.4352C104.131 17.7128 103.749 17.9501 103.345 18.1425C102.915 18.3452 102.461 18.495 101.994 18.5885C101.455 18.6966 100.906 18.7491 100.356 18.7452Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M108.736 5.99609H118.166V8.44813H111.448V10.9897H117.363V13.4418H111.457V16.0729H118.259V18.525H108.742L108.736 5.99609Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_10206">
          <rect
            className="rect"
            fill="white"
            height="23.6265"
            transform="translate(0.125 0.270996)"
            width="118.132"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
