/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite7 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-7 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 162 28"
      width="162"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_7230)">
        <path
          className="path-3"
          d="M7.14253 1.47412L0.933594 26.3699H7.15586L11.0373 10.8042H17.2662L19.5937 1.47412H13.3648H7.14253Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M32.0382 1.47412H25.816L23.4885 10.8042L21.9346 17.0331H15.7123L13.3848 26.3699H19.607H25.8293L29.7107 10.8042L32.0382 1.47412Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M65.6236 2.98101C65.6285 3.21193 65.5833 3.44118 65.4912 3.653C65.3991 3.86482 65.2623 4.0542 65.0901 4.20813C64.777 4.51604 64.3554 4.6886 63.9163 4.6886C63.4772 4.6886 63.0556 4.51604 62.7425 4.20813C62.4227 3.90134 62.2364 3.48104 62.224 3.03804C62.2115 2.59504 62.3738 2.16491 62.6758 1.84059L62.7558 1.75391C63.0689 1.446 63.4905 1.27344 63.9296 1.27344C64.3687 1.27344 64.7903 1.446 65.1034 1.75391C65.2757 1.90706 65.4117 2.09663 65.5017 2.30887C65.5917 2.52111 65.6333 2.75069 65.6236 2.98101ZM38.2803 1.46712H48.5774C51.4251 1.46712 53.6592 2.28742 55.0997 3.6746C55.6215 4.19999 56.0321 4.82505 56.3071 5.51252C56.582 6.19999 56.7158 6.93579 56.7003 7.67606V7.74944C56.7003 10.9906 54.6996 12.6646 52.7522 13.5115C55.7066 14.4052 58.0875 16.1125 58.0875 19.5137V19.5871C58.0875 23.8553 54.4995 26.3829 49.0509 26.3829H38.2803V1.46712ZM48.0972 12.5712C51.4718 12.5712 53.8259 11.0373 53.8259 8.10287V8.03614C53.8259 5.57523 51.8652 3.97465 48.2839 3.97465H41.0613V12.5645L48.0972 12.5712ZM49.0909 23.8486C52.8656 23.8486 55.2131 22.1813 55.2131 19.387V19.3203C55.2131 16.6126 52.9323 15.0454 48.5907 15.0454H41.0613V23.8486L49.0909 23.8486ZM62.5425 7.97616H65.2901V26.3762H62.5425V7.97616ZM72.5461 20.5407V10.397H68.3846V7.99617H72.5461V1.46712H75.2938V7.99617H82.8499V10.397H75.3205V20.1873C75.3205 22.6615 76.4876 24.2354 78.715 24.1888C80.0547 24.215 81.3873 23.9888 82.6431 23.5218V25.916C81.1967 26.5104 79.6365 26.7769 78.0748 26.6963C74.7536 26.6229 72.5728 24.2821 72.5728 20.5141L72.5461 20.5407ZM86.8313 1.46712H92.7402L99.2892 11.9976L105.832 1.46712H111.74V26.3762H106.305V10.117L99.2959 20.7875H99.1492L92.2133 10.257V26.3962H86.8313V1.46712ZM116.616 1.46712H135.409V6.34223H122.071V11.3974H133.175V16.2725H122.064V21.5011H135.582V26.3762H116.616V1.46712ZM145.833 13.7049L137.503 1.46712H143.912L149.141 9.5434L154.409 1.46712H160.638L152.322 13.6382L160.992 26.3762H154.589L148.987 17.8464L143.365 26.3896H137.136L145.833 13.7049Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_7230">
          <rect
            className="rect"
            fill="white"
            height="26.9834"
            transform="translate(0.17041 0.500488)"
            width="161.585"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
