/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite6 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-6 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 118 21"
      width="118"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_7698)">
        <path
          className="path-3"
          d="M5.39602 1.29053L0.898926 19.3224H5.40568L8.21697 8.04825H12.7286L14.4144 1.29053H9.90278H5.39602Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M23.4281 1.29053H18.9213L17.2355 8.04825L16.11 12.5598H11.6033L9.91748 19.3224H14.4242H18.931L21.7423 8.04825L23.4281 1.29053Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M47.7538 2.38229C47.7574 2.54955 47.7247 2.71559 47.658 2.86901C47.5913 3.02243 47.4921 3.1596 47.3674 3.27109C47.1406 3.49411 46.8353 3.61909 46.5173 3.61909C46.1992 3.61909 45.8939 3.49411 45.6671 3.27109C45.4355 3.04888 45.3005 2.74446 45.2915 2.4236C45.2825 2.10274 45.4 1.7912 45.6188 1.5563L45.6767 1.49351C45.9035 1.27049 46.2088 1.14551 46.5269 1.14551C46.8449 1.14551 47.1503 1.27049 47.377 1.49351C47.5018 1.60444 47.6004 1.74174 47.6655 1.89546C47.7307 2.04919 47.7609 2.21547 47.7538 2.38229ZM27.9492 1.28579H35.4073C37.4699 1.28579 39.0881 1.87993 40.1315 2.88465C40.5094 3.26519 40.8067 3.71792 41.0059 4.21585C41.2051 4.71378 41.302 5.24672 41.2908 5.78289V5.83604C41.2908 8.18362 39.8416 9.39605 38.4312 10.0095C40.571 10.6568 42.2955 11.8934 42.2955 14.3569V14.41C42.2955 17.5015 39.6967 19.3322 35.7503 19.3322H27.9492V1.28579ZM35.0596 9.3284C37.5037 9.3284 39.2089 8.21741 39.2089 6.09203V6.04369C39.2089 4.26128 37.7887 3.10198 35.1948 3.10198H29.9635V9.32353L35.0596 9.3284ZM35.7793 17.4966C38.5133 17.4966 40.2136 16.289 40.2136 14.2651V14.2167C40.2136 12.2556 38.5616 11.1204 35.417 11.1204H29.9635V17.4966L35.7793 17.4966ZM45.5222 6.00025H47.5123V19.3273H45.5222V6.00025ZM52.7678 15.1007V7.75369H49.7536V6.01475H52.7678V1.28579H54.7579V6.01475H60.2307V7.75369H54.7772V14.8447C54.7772 16.6368 55.6225 17.7768 57.2359 17.7429C58.2062 17.762 59.1714 17.5981 60.081 17.2599V18.994C59.0334 19.4245 57.9033 19.6175 56.7722 19.5592C54.3666 19.506 52.7871 17.8106 52.7871 15.0814L52.7678 15.1007ZM63.1145 1.28579H67.3942L72.1377 8.91298L76.8763 1.28579H81.156V19.3273H77.2192V7.55082L72.1425 15.2794H72.0362L67.0126 7.65225V19.3418H63.1145V1.28579ZM84.687 1.28579H98.2991V4.81681H88.6383V8.47825H96.6809V12.0093H88.6335V15.7963H98.4247V19.3273H84.687V1.28579ZM105.849 10.1496L99.8158 1.28579H104.458L108.245 7.1354L112.061 1.28579H116.572L110.549 10.1013L116.828 19.3273H112.191L108.134 13.1492L104.062 19.337H99.5501L105.849 10.1496Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_7698">
          <rect
            className="rect"
            fill="white"
            height="19.5439"
            transform="translate(0.346191 0.585449)"
            width="117.035"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
