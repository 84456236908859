/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite8 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-8 ${className}`}
      fill="none"
      height="47"
      viewBox="0 0 233 47"
      width="233"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_6268)">
        <path
          className="path-3"
          d="M14.4245 19.5263L23.45 10.5045L32.4792 19.5336L37.7279 14.2813L23.45 0L9.17212 14.2777L14.4245 19.5263Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.210938 23.2358L5.46138 17.9855L10.7118 23.2358L5.46138 28.4862L0.210938 23.2358Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M14.4245 26.9441L23.45 35.9695L32.4792 26.9404L37.7316 32.1854L37.7279 32.189L23.45 46.4704L9.17214 32.1964L9.16479 32.189L14.4245 26.9441Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M36.1794 23.2373L41.4299 17.9869L46.6803 23.2373L41.4299 28.4877L36.1794 23.2373Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M28.7758 23.2332L23.4501 17.9038L19.5117 21.8421L19.0566 22.2936L18.1243 23.2258L18.1169 23.2332L18.1243 23.2442L23.4501 28.5662L28.7758 23.2368L28.7795 23.2332H28.7758Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M54.8726 11.2607H66.2949C69.1284 11.2607 71.272 11.9948 72.7254 13.463C73.273 14.0053 73.7047 14.6532 73.9943 15.3674C74.284 16.0816 74.4254 16.8472 74.4102 17.6178V17.6875C74.42 18.3188 74.3321 18.9477 74.1496 19.5521C73.9869 20.0723 73.7509 20.5667 73.4485 21.0202C73.1666 21.4479 72.8256 21.8334 72.4355 22.1654C72.0544 22.4934 71.6369 22.7766 71.1912 23.0095C72.4764 23.4572 73.6321 24.2134 74.557 25.2118C75.3743 26.1416 75.7829 27.4262 75.7829 29.0656V29.1354C75.8061 30.1592 75.5833 31.1737 75.1332 32.0937C74.6971 32.9413 74.0577 33.6676 73.2723 34.2078C72.3844 34.8031 71.3957 35.2321 70.3544 35.4741C69.1172 35.7674 67.8489 35.9092 66.5775 35.8961H54.8726V11.2607ZM65.1497 21.2221C66.1379 21.2698 67.1214 21.0572 68.0016 20.6055C68.3449 20.405 68.6252 20.1123 68.8107 19.7608C68.9962 19.4092 69.0796 19.0127 69.0514 18.6161V18.5464C69.0695 18.181 68.9953 17.817 68.8356 17.4879C68.6758 17.1588 68.4357 16.8753 68.1374 16.6635C67.5281 16.2304 66.6485 16.0138 65.4984 16.0138H60.1543V21.2221H65.1497ZM66.5922 31.1504C67.5767 31.2055 68.5568 30.9803 69.4184 30.5007C69.7541 30.2851 70.0258 29.9834 70.2052 29.627C70.3847 29.2707 70.4653 28.8728 70.4388 28.4747V28.405C70.452 28.0266 70.3723 27.6507 70.2065 27.3103C70.0408 26.9699 69.7941 26.6753 69.4881 26.4523C68.8568 25.963 67.8365 25.7183 66.427 25.7183H60.1543V31.1504H66.5922Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M82.5879 11.2607H88.0091V35.9035H82.5879V11.2607Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M96.2124 11.2607H101.212L112.766 26.4266V11.2607H118.117V35.9035H113.504L101.571 20.2384V35.9035H96.2124V11.2607Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M134.531 11.0845H139.523L150.083 35.9034H144.423L142.17 30.3758H131.749L129.496 35.9034H123.968L134.531 11.0845ZM140.235 25.5897L136.954 17.5957L133.687 25.5897H140.235Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M155.949 11.2759H160.948L172.495 26.4418V11.2759H177.847V35.9186H173.233L161.301 20.2536V35.9186H155.949V11.2759Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M197.354 36.3366C195.627 36.3514 193.914 36.0168 192.318 35.3529C190.817 34.7329 189.454 33.8185 188.311 32.6637C187.169 31.5089 186.268 30.137 185.664 28.6289C185.026 27.0514 184.703 25.3644 184.713 23.6629V23.5931C184.685 20.2393 185.975 17.0085 188.307 14.5971C189.454 13.4192 190.827 12.4853 192.344 11.8517C193.994 11.1681 195.766 10.8272 197.552 10.8497C198.609 10.8418 199.664 10.9364 200.702 11.1323C201.59 11.3003 202.458 11.5598 203.293 11.9067C204.05 12.2256 204.771 12.6268 205.44 13.1033C206.094 13.568 206.708 14.086 207.275 14.6522L203.829 18.6308C202.948 17.8183 201.96 17.1305 200.893 16.5864C199.844 16.0849 198.693 15.8337 197.53 15.8524C196.563 15.842 195.605 16.0377 194.719 16.4262C193.833 16.8148 193.04 17.3873 192.392 18.106C191.75 18.8227 191.247 19.653 190.909 20.5541C190.555 21.5064 190.378 22.5149 190.384 23.5307V23.6005C190.381 24.6148 190.558 25.6216 190.909 26.5735C191.243 27.4778 191.74 28.3128 192.377 29.0363C193.02 29.7638 193.811 30.3448 194.697 30.7399C195.584 31.1351 196.545 31.3351 197.516 31.3266C198.752 31.3655 199.978 31.0995 201.087 30.5521C202.143 29.9768 203.119 29.2661 203.99 28.438L207.441 31.9248C206.831 32.586 206.172 33.1996 205.47 33.76C204.779 34.306 204.029 34.7726 203.234 35.1511C202.387 35.5498 201.495 35.8444 200.577 36.0283C199.516 36.241 198.436 36.3443 197.354 36.3366Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M213.838 11.2607H232.384V16.0836H219.171V21.0826H230.806V25.9055H219.189V31.0806H232.568V35.9035H213.849L213.838 11.2607Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_6268">
          <rect
            className="rect"
            fill="white"
            height="46.4703"
            transform="translate(0.212891)"
            width="232.352"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
