/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWooxWhite1 = ({ className }) => {
  return (
    <svg
      className={`logo-woox-white-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 71 16"
      width="71"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-3"
        d="M29.5141 0.422852C28.0701 0.422852 26.6567 0.853002 25.4584 1.6621C24.2602 2.47119 23.3179 3.61826 22.7649 4.95992C22.2118 6.30158 22.0684 7.78663 22.345 9.21022C22.6317 10.6338 23.3179 11.9448 24.3421 12.9792C25.3663 14.0033 26.6669 14.71 28.0803 14.9968C29.4937 15.2835 30.9685 15.1299 32.2999 14.5769C33.6313 14.0238 34.7784 13.0816 35.5772 11.8628C36.3761 10.6543 36.8062 9.23071 36.8062 7.77639C36.8062 5.82022 36.0381 3.95624 34.6657 2.57361C33.3036 1.20122 31.4498 0.422852 29.5141 0.422852ZM29.5141 11.7502C28.7358 11.7502 27.9779 11.5146 27.3327 11.0845C26.6874 10.6441 26.1856 10.0296 25.8886 9.3024C25.5916 8.57524 25.5096 7.78663 25.6633 7.0185C25.8169 6.25037 26.1856 5.5437 26.7386 4.99065C27.2917 4.4376 27.9881 4.05865 28.7563 3.90503C29.5141 3.7514 30.313 3.83333 31.0299 4.13034C31.7468 4.42735 32.3613 4.93944 32.7915 5.58466C33.2216 6.24013 33.4572 6.99802 33.4572 7.78663C33.4572 8.84152 33.0475 9.84521 32.3101 10.5929C31.5522 11.3303 30.5588 11.7502 29.5141 11.7502Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M45.9721 0.422852C44.5281 0.422852 43.1147 0.853002 41.9164 1.6621C40.7182 2.47119 39.7759 3.61826 39.2229 4.95992C38.6698 6.30158 38.5264 7.78663 38.803 9.21022C39.0795 10.6338 39.7759 11.9448 40.8001 12.9792C41.8243 14.0033 43.125 14.71 44.5383 14.9968C45.9517 15.2835 47.4265 15.1299 48.7579 14.5769C50.0893 14.0238 51.2364 13.0816 52.0352 11.8628C52.8341 10.6543 53.2642 9.23071 53.2642 7.77639C53.2642 5.82022 52.4961 3.95624 51.1237 2.57361C49.7718 1.20122 47.9078 0.422852 45.9721 0.422852ZM45.9721 11.7502C45.1938 11.7502 44.4359 11.5146 43.7907 11.0845C43.1454 10.6441 42.6436 10.0296 42.3466 9.3024C42.0496 8.57524 41.9676 7.78663 42.1213 7.0185C42.2749 6.25037 42.6436 5.5437 43.1966 4.99065C43.7497 4.4376 44.4461 4.05865 45.2143 3.90503C45.9721 3.7514 46.771 3.83333 47.4879 4.13034C48.2048 4.42735 48.8193 4.93944 49.2495 5.58466C49.6796 6.24013 49.9152 6.99802 49.9152 7.78663C49.9152 8.84152 49.5055 9.84521 48.7681 10.5929C48.0205 11.3303 47.0168 11.7502 45.9721 11.7502Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M21.6076 0.545898H18.0537L16.415 5.94327H19.9177L21.6076 0.545898Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M13.4653 5.68723L15.2269 9.84536L15.8619 7.72532H19.3543L17.3879 14.1776C17.2343 14.6794 16.7631 15.0277 16.2306 15.0277H14.2437C13.7623 15.0277 13.3117 14.7306 13.1171 14.2595L10.9459 8.90312L8.7951 14.239C8.62099 14.6897 8.17036 15.0072 7.66851 15.0072H5.66114C5.12857 15.0072 4.65746 14.659 4.50383 14.1571L0.345703 0.545898H3.84836L6.61362 9.83511L8.44688 5.64626C8.84631 4.60161 9.84999 3.92566 10.9459 3.92566C12.0724 3.92566 13.0761 4.62209 13.4653 5.68723Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M60.9972 3.85397L58.488 0.545898H54.125L58.8157 6.73188L60.9972 3.85397Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M69.7839 0.556152H65.421L54.4316 15.0277H58.7946L69.7839 0.556152Z"
        fill="white"
      />

      <path
        className="path-3"
        d="M65.7289 15.0273L63.2197 11.7192L65.4012 8.84131L70.0919 15.0273H65.7289Z"
        fill="white"
      />
    </svg>
  );
};
