/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite7 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-7 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 215 44"
      width="215"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_7226)">
        <path
          className="path-3"
          d="M13.6215 18.7401L21.9137 10.4513L30.2093 18.7468L35.0315 13.9213L21.9137 0.800293L8.7959 13.9179L13.6215 18.7401Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.5625 22.1484L5.38634 17.3247L10.2102 22.1484L5.38634 26.9722L0.5625 22.1484Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M13.6214 25.5551L21.9136 33.8472L30.2092 25.5518L35.0348 30.3705L35.0314 30.3739L21.9136 43.4949L8.79581 30.3807L8.78906 30.3739L13.6214 25.5551Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M33.6089 22.1494L38.4327 17.3257L43.2566 22.1494L38.4327 26.9732L33.6089 22.1494Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M26.8065 22.1459L21.9135 17.2495L18.2951 20.8678L17.877 21.2826L17.0204 22.1391L17.0137 22.1459L17.0204 22.156L21.9135 27.0456L26.8065 22.1492L26.8099 22.1459H26.8065Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M50.7827 11.146H61.277C63.8803 11.146 65.8497 11.8204 67.185 13.1693C67.6882 13.6675 68.0848 14.2628 68.3509 14.919C68.6169 15.5752 68.7469 16.2786 68.7329 16.9865V17.0506C68.7419 17.6306 68.6612 18.2084 68.4935 18.7637C68.3441 19.2416 68.1272 19.6958 67.8494 20.1125C67.5904 20.5054 67.277 20.8596 66.9186 21.1646C66.5685 21.466 66.1849 21.7262 65.7755 21.9402C66.9563 22.3515 68.0181 23.0462 68.8678 23.9635C69.6186 24.8178 69.9941 25.998 69.9941 27.5042V27.5683C70.0154 28.509 69.8107 29.4411 69.3972 30.2862C68.9965 31.065 68.4091 31.7323 67.6875 32.2286C66.8717 32.7755 65.9633 33.1697 65.0066 33.392C63.87 33.6615 62.7047 33.7917 61.5366 33.7798H50.7827V11.146ZM60.2248 20.298C61.1328 20.3419 62.0363 20.1465 62.845 19.7315C63.1604 19.5472 63.4179 19.2784 63.5884 18.9554C63.7588 18.6324 63.8354 18.2681 63.8095 17.9038V17.8397C63.8262 17.504 63.758 17.1695 63.6112 16.8672C63.4645 16.5648 63.2439 16.3043 62.9698 16.1098C62.41 15.7119 61.6018 15.5129 60.5452 15.5129H55.6353V20.298H60.2248ZM61.5501 29.4196C62.4547 29.4703 63.3551 29.2633 64.1467 28.8227C64.4551 28.6246 64.7047 28.3474 64.8696 28.02C65.0345 27.6926 65.1086 27.3271 65.0842 26.9613V26.8972C65.0963 26.5496 65.0231 26.2043 64.8708 25.8915C64.7185 25.5788 64.4919 25.3081 64.2108 25.1033C63.6308 24.6536 62.6933 24.4288 61.3984 24.4288H55.6353V29.4196H61.5501Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M76.2461 11.146H81.2268V33.7865H76.2461V11.146Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M88.7637 11.146H93.3566L103.972 25.0797V11.146H108.889V33.7865H104.65L93.6871 19.3942V33.7865H88.7637V11.146Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M123.97 10.9839H128.556L138.258 33.7863H133.058L130.987 28.7078H121.414L119.343 33.7863H114.265L123.97 10.9839ZM129.21 24.3106L126.195 16.9661L123.194 24.3106H129.21Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M143.647 11.1602H148.24L158.849 25.0938V11.1602H163.765V33.8007H159.527L148.564 19.4084V33.8007H143.647V11.1602Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M181.688 34.1847C180.101 34.1982 178.527 33.8908 177.061 33.2809C175.682 32.7112 174.43 31.8711 173.38 30.8101C172.33 29.7492 171.503 28.4887 170.948 27.1032C170.362 25.6538 170.065 24.1039 170.074 22.5407V22.4766C170.048 19.3952 171.234 16.427 173.376 14.2115C174.429 13.1293 175.691 12.2713 177.085 11.6891C178.601 11.0611 180.229 10.7479 181.87 10.7685C182.841 10.7613 183.81 10.8483 184.763 11.0282C185.579 11.1825 186.377 11.421 187.144 11.7397C187.84 12.0326 188.502 12.4013 189.117 12.839C189.717 13.266 190.281 13.7419 190.803 14.2621L187.637 17.9175C186.827 17.171 185.92 16.539 184.939 16.0392C183.975 15.5784 182.918 15.3476 181.85 15.3648C180.961 15.3553 180.08 15.535 179.266 15.892C178.453 16.249 177.724 16.775 177.129 17.4352C176.539 18.0938 176.077 18.8566 175.766 19.6845C175.442 20.5594 175.278 21.486 175.284 22.4193V22.4833C175.281 23.4153 175.444 24.3403 175.766 25.2148C176.073 26.0456 176.53 26.8127 177.115 27.4775C177.706 28.1459 178.433 28.6797 179.247 29.0427C180.062 29.4058 180.945 29.5896 181.836 29.5817C182.972 29.6175 184.099 29.3731 185.117 28.8702C186.087 28.3416 186.984 27.6886 187.785 26.9278L190.955 30.1313C190.395 30.7388 189.79 31.3025 189.144 31.8174C188.509 32.319 187.821 32.7477 187.09 33.0954C186.312 33.4618 185.492 33.7324 184.649 33.9014C183.674 34.0968 182.682 34.1917 181.688 34.1847Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M196.833 11.146H213.872V15.577H201.732V20.1698H212.422V24.6008H201.749V29.3555H214.041V33.7865H196.843L196.833 11.146Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_7226">
          <rect
            className="rect"
            fill="white"
            height="42.6946"
            transform="translate(0.564453 0.800293)"
            width="213.473"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
