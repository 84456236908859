/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite3 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-3 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 131 27"
      width="131"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_9761)">
        <path
          className="path-3"
          d="M8.28972 11.755L13.3503 6.69652L18.413 11.7591L21.3559 8.81417L13.3503 0.806641L5.34473 8.81211L8.28972 11.755Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.320312 13.835L3.26423 10.8911L6.20814 13.835L3.26422 16.7788L0.320312 13.835Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M8.28993 15.9142L13.3505 20.9747L18.4132 15.9121L21.3582 18.8529L21.3561 18.855L13.3505 26.8625L5.34494 18.8591L5.34082 18.855L8.28993 15.9142Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M20.4873 13.8359L23.4312 10.8921L26.3751 13.8359L23.4312 16.7798L20.4873 13.8359Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M16.3368 13.8334L13.3506 10.8452L11.1424 13.0534L10.8872 13.3065L10.3645 13.8293L10.3604 13.8334L10.3645 13.8395L13.3506 16.8236L16.3368 13.8354L16.3388 13.8334H16.3368Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M30.9688 7.12061H37.3732C38.962 7.12061 40.1639 7.5322 40.9788 8.35538C41.2859 8.65946 41.5279 9.02275 41.6903 9.42321C41.8527 9.82367 41.932 10.2529 41.9235 10.685V10.7241C41.929 11.078 41.8797 11.4307 41.7773 11.7695C41.6862 12.0612 41.5538 12.3384 41.3843 12.5927C41.2262 12.8325 41.035 13.0487 40.8163 13.2348C40.6026 13.4187 40.3685 13.5775 40.1186 13.7081C40.8392 13.9591 41.4872 14.3831 42.0058 14.9429C42.464 15.4643 42.6931 16.1846 42.6931 17.1038V17.1429C42.7062 17.717 42.5812 18.2858 42.3289 18.8016C42.0843 19.2768 41.7259 19.6841 41.2855 19.987C40.7876 20.3208 40.2332 20.5613 39.6494 20.697C38.9557 20.8615 38.2446 20.9409 37.5317 20.9336H30.9688V7.12061ZM36.7311 12.7059C37.2852 12.7327 37.8367 12.6135 38.3302 12.3602C38.5227 12.2478 38.6798 12.0837 38.7838 11.8865C38.8879 11.6894 38.9346 11.4671 38.9188 11.2448V11.2057C38.929 11.0008 38.8874 10.7967 38.7978 10.6122C38.7082 10.4276 38.5736 10.2687 38.4063 10.1499C38.0647 9.90708 37.5715 9.78566 36.9266 9.78566H33.9302V12.7059H36.7311ZM37.5399 18.2727C38.092 18.3036 38.6415 18.1773 39.1246 17.9084C39.3128 17.7875 39.4651 17.6184 39.5658 17.4186C39.6664 17.2188 39.7116 16.9956 39.6967 16.7724V16.7333C39.7041 16.5212 39.6594 16.3104 39.5665 16.1196C39.4736 15.9287 39.3353 15.7635 39.1637 15.6385C38.8097 15.3641 38.2376 15.2269 37.4473 15.2269H33.9302V18.2727H37.5399Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M46.5088 7.12061H49.5484V20.9378H46.5088V7.12061Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M54.1484 7.12061H56.9514L63.43 15.6241V7.12061H66.4305V20.9378H63.8436L57.1531 12.1544V20.9378H54.1484V7.12061Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M75.6338 7.02148H78.4327L84.3536 20.9374H81.1801L79.9165 17.8381H74.0739L72.8103 20.9374H69.7109L75.6338 7.02148ZM78.832 15.1545L76.9921 10.6723L75.1605 15.1545H78.832Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M87.6426 7.12891H90.4456L96.92 15.6324V7.12891H99.9206V20.9461H97.3337L90.6431 12.1627V20.9461H87.6426V7.12891Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M110.858 21.1802C109.89 21.1885 108.929 21.0009 108.035 20.6287C107.193 20.281 106.429 19.7683 105.788 19.1208C105.147 18.4733 104.643 17.7041 104.304 16.8585C103.946 15.974 103.765 15.0281 103.771 14.0741V14.035C103.755 12.1545 104.478 10.343 105.786 8.99092C106.429 8.33047 107.199 7.80686 108.049 7.45157C108.975 7.0683 109.968 6.87715 110.97 6.88974C111.562 6.88532 112.153 6.93839 112.735 7.04821C113.233 7.14239 113.72 7.28791 114.188 7.48244C114.613 7.6612 115.017 7.88619 115.392 8.15333C115.759 8.41388 116.103 8.70436 116.421 9.02179L114.489 11.2526C113.995 10.7971 113.441 10.4114 112.842 10.1063C112.254 9.82515 111.609 9.68425 110.957 9.69474C110.415 9.68896 109.877 9.79864 109.381 10.0165C108.884 10.2344 108.439 10.5554 108.076 10.9583C107.716 11.3602 107.434 11.8257 107.245 12.331C107.046 12.8649 106.947 13.4304 106.95 14V14.0391C106.948 14.6078 107.048 15.1724 107.245 15.706C107.432 16.2131 107.711 16.6813 108.068 17.0869C108.428 17.4949 108.872 17.8206 109.369 18.0422C109.866 18.2637 110.405 18.3759 110.949 18.3711C111.642 18.3929 112.33 18.2438 112.951 17.9369C113.543 17.6143 114.091 17.2158 114.579 16.7515L116.514 18.7066C116.172 19.0773 115.803 19.4213 115.409 19.7355C115.021 20.0417 114.601 20.3033 114.155 20.5155C113.68 20.7391 113.18 20.9042 112.665 21.0074C112.071 21.1266 111.465 21.1845 110.858 21.1802Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M120.101 7.12061H130.5V9.82477H123.091V12.6277H129.615V15.3319H123.101V18.2336H130.603V20.9378H120.107L120.101 7.12061Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_9761">
          <rect
            className="rect"
            fill="white"
            height="26.0558"
            transform="translate(0.321289 0.806641)"
            width="130.279"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
