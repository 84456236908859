/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoAceWhite3 = ({ className }) => {
  return (
    <svg
      className={`logo-ace-white-3 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 78 27"
      width="78"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_9767)">
        <path
          className="path-3"
          d="M16.607 0.691895C16.6614 0.851083 16.7701 0.977916 16.8569 1.1164C17.7398 2.5245 18.6265 3.93002 19.5146 5.33554C20.3962 6.7333 21.2804 8.12975 22.162 9.52751C23.0293 10.902 23.8941 12.2777 24.7601 13.6535C25.4216 14.7031 26.0858 15.7514 26.7473 16.801C27.4606 17.9321 28.1713 19.0633 28.8846 20.1944C29.5461 21.244 30.2102 22.2924 30.8717 23.342C31.5656 24.4408 32.2595 25.5408 32.9521 26.6409C32.9793 26.6836 33 26.7289 33.0233 26.7742H14.9849C15.0173 26.6836 15.0704 26.6021 15.1183 26.5193C15.6361 25.6237 16.1565 24.7294 16.6743 23.8338C17.2517 22.8346 17.8278 21.8355 18.4039 20.8364C18.699 20.3252 18.9916 19.8114 19.2893 19.3027C19.3411 19.2134 19.3334 19.15 19.2777 19.0672C19.1366 18.8588 19.0045 18.644 18.8699 18.4304C18.1281 17.2527 17.3863 16.0749 16.6446 14.8972C16.5798 14.7937 16.563 14.7937 16.497 14.8972C16.2109 15.3502 15.9248 15.8032 15.6387 16.2561C15.227 16.9058 14.8154 17.5555 14.405 18.2065C13.8626 19.0659 13.3227 19.9278 12.7803 20.7872C12.3233 21.512 11.8651 22.2341 11.4081 22.9589C10.8488 23.8454 10.2883 24.732 9.73164 25.6198C9.49085 26.0042 9.23841 26.3808 9.01445 26.7742C6.08099 26.7742 3.14753 26.7742 0.214064 26.7716C0.179111 26.7716 0.0962591 26.8273 0.104026 26.7199C0.15322 26.6901 0.1817 26.6435 0.211475 26.5969C0.705995 25.8152 1.19922 25.0322 1.69374 24.2505C2.31901 23.2591 2.94428 22.2678 3.57085 21.2764C4.13527 20.3821 4.70229 19.4891 5.26671 18.5935C5.94118 17.5245 6.61435 16.4554 7.28881 15.3864C7.74579 14.6617 8.20406 13.9395 8.66104 13.2147C9.21381 12.3398 9.764 11.4637 10.3168 10.5875C10.7634 9.87824 11.2113 9.1703 11.6579 8.46107C12.2029 7.59654 12.7454 6.732 13.2904 5.86876C13.7914 5.07411 14.2924 4.28076 14.7933 3.48611C15.3798 2.55557 15.9649 1.62632 16.5501 0.695777C16.5682 0.695777 16.5863 0.695777 16.6044 0.695777L16.607 0.691895Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M77.1683 23.364C76.4174 23.364 75.6653 23.3653 74.9144 23.3666C74.873 23.3666 74.8316 23.3678 74.7902 23.3666C74.7021 23.364 74.6672 23.4015 74.6775 23.4934C74.7008 23.6888 74.7319 23.8829 74.8044 24.068C75.075 24.7578 75.7896 24.9196 76.3462 24.609C76.4977 24.5236 76.5987 24.3864 76.6776 24.2324C76.7372 24.1185 76.9585 24.0551 77.0686 24.1198C77.1307 24.156 77.1022 24.2091 77.0854 24.2531C77 24.4809 76.864 24.6737 76.675 24.8277C76.1611 25.2445 75.2925 25.2263 74.807 24.7811C74.5753 24.5676 74.438 24.2932 74.3617 23.9904C74.2128 23.3924 74.2477 22.81 74.5183 22.2509C74.9572 21.345 76.1443 21.2867 76.7216 21.8174C76.9365 22.0141 77.0673 22.2652 77.1385 22.546C77.1475 22.581 77.1566 22.6172 77.1657 22.6521V23.3666L77.1683 23.364ZM75.752 23.0068C76.0588 23.0068 76.3656 23.0081 76.6725 23.0068C76.7903 23.0068 76.8123 22.9783 76.7993 22.867C76.7799 22.7117 76.7436 22.5616 76.6776 22.4179C76.4135 21.8381 75.62 21.6556 75.128 22.062C74.8756 22.2716 74.7785 22.5577 74.7216 22.8644C74.6983 22.9925 74.7086 23.0068 74.8445 23.0068C75.1462 23.0081 75.4491 23.0068 75.7507 23.0068H75.752Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M67.5127 10.9835C67.5127 9.08227 67.5127 7.18236 67.5127 5.28116C67.5127 5.23068 67.514 5.18021 67.5127 5.12973C67.5114 5.04302 67.5529 5.0029 67.6383 5.00549C67.6797 5.00549 67.7211 5.00549 67.7626 5.00549C70.6041 5.00549 73.447 5.00549 76.2885 5.00549C76.4551 5.00549 76.5384 5.08832 76.5384 5.25398C76.5384 5.77555 76.5384 6.29841 76.5384 6.81998C76.5384 6.98823 76.4521 7.07235 76.2795 7.07235C74.2263 7.07235 72.1718 7.07235 70.1187 7.07235C69.9633 7.07235 69.8856 7.15 69.8856 7.30531C69.8856 8.1349 69.8856 8.9632 69.8856 9.79279C69.8856 9.93602 69.956 10.0076 70.0967 10.0076C71.9116 10.0076 73.7266 10.0076 75.5416 10.0076C75.709 10.0076 75.7927 10.0918 75.7927 10.26C75.7927 10.8191 75.7927 11.3769 75.7927 11.936C75.7927 12.1198 75.7823 12.1289 75.5972 12.1289C73.7913 12.1289 71.9854 12.1289 70.1795 12.1289C70.1342 12.1289 70.0876 12.1289 70.0423 12.1289C69.8973 12.1314 69.8856 12.1405 69.8856 12.2867C69.8856 13.093 69.8856 13.8993 69.8856 14.7056C69.8856 14.8946 69.8856 14.8946 70.0785 14.8946C72.1459 14.8946 74.2133 14.8946 76.2807 14.8946C76.4525 14.8946 76.5384 14.9791 76.5384 15.1483C76.5384 15.6983 76.5384 16.2471 76.5384 16.7971C76.5384 16.9809 76.5293 16.9899 76.3429 16.9899C73.4638 16.9899 70.586 16.9899 67.7069 16.9899C67.5218 16.9899 67.5127 16.9809 67.5127 16.7958C67.5127 14.8584 67.5127 12.9209 67.5127 10.9835Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M50.5691 11.0738C50.5536 10.0656 50.7737 9.16997 51.197 8.32614C51.4572 7.80845 51.7834 7.33477 52.1731 6.9038C52.7051 6.31363 53.3304 5.84901 54.0347 5.4931C54.7829 5.11519 55.5765 4.89647 56.4166 4.83305C57.4665 4.7541 58.4711 4.92753 59.4368 5.33391C60.1268 5.62511 60.7443 6.0302 61.2906 6.5453C61.7023 6.93356 62.0674 7.36065 62.3561 7.84987C62.4376 7.98835 62.4363 7.9987 62.29 8.05694C61.6428 8.3132 60.9942 8.56686 60.3469 8.82571C60.2524 8.86324 60.1967 8.84124 60.1385 8.76229C59.7294 8.20319 59.2258 7.75021 58.6122 7.42536C58.1164 7.16264 57.5908 7.00474 57.0238 6.98533C56.0218 6.95039 55.1454 7.27653 54.3945 7.92105C53.5803 8.61993 53.0896 9.50776 52.9679 10.5807C52.832 11.7752 53.2061 12.8106 53.9881 13.7088C54.5758 14.3844 55.3137 14.8179 56.1927 14.9875C57.2244 15.1868 58.1643 14.9448 59.0239 14.3559C59.4601 14.0582 59.8265 13.6894 60.132 13.261C60.198 13.1678 60.2589 13.1549 60.3611 13.1963C61.0032 13.4538 61.6479 13.7062 62.2913 13.9612C62.435 14.0181 62.4389 14.0336 62.3586 14.1695C61.7593 15.1751 60.9269 15.9335 59.8964 16.4823C59.1922 16.8576 58.4413 17.088 57.6503 17.1747C56.4399 17.308 55.2748 17.1346 54.1706 16.6052C53.456 16.2623 52.8152 15.8171 52.2754 15.2385C51.32 14.2174 50.7348 13.0203 50.5924 11.6186C50.5717 11.4167 50.5717 11.2161 50.5691 11.0751V11.0738Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M46.1984 16.9898C45.8359 16.9898 45.4735 16.9846 45.1123 16.9924C44.9738 16.9963 44.8974 16.9523 44.8365 16.8254C44.5841 16.3064 44.3187 15.7939 44.065 15.2749C44.0093 15.1611 43.9407 15.1158 43.8113 15.1171C42.0196 15.1209 40.2266 15.1209 38.435 15.1171C38.3016 15.1171 38.2278 15.1611 38.1709 15.2814C37.9249 15.7939 37.6673 16.3 37.4226 16.8125C37.3618 16.9406 37.2841 16.995 37.1378 16.9937C36.409 16.9872 35.6802 16.9911 34.9513 16.9911C34.9151 16.9911 34.8775 16.9911 34.8413 16.9872C34.7869 16.982 34.7701 16.951 34.7908 16.9005C34.805 16.8668 34.8219 16.8345 34.8387 16.8008C35.4963 15.5428 36.1527 14.2849 36.8116 13.0256C37.1068 12.4613 37.4032 11.8983 37.6971 11.3341C38.3728 10.0398 39.0486 8.74433 39.7256 7.44882C40.0312 6.86384 40.3367 6.28014 40.6422 5.69645C40.7393 5.51009 40.8403 5.32501 40.9348 5.13606C40.9749 5.05452 41.0331 5.02734 41.1212 5.02734C41.2131 5.02734 41.2752 5.05193 41.3192 5.13865C41.5173 5.52562 41.7218 5.91 41.9238 6.29568C42.4274 7.26245 42.9297 8.22923 43.4333 9.19601C43.8566 10.0075 44.2812 10.819 44.7058 11.6304C45.2443 12.6606 45.7829 13.6921 46.3227 14.7223C46.6748 15.3953 47.0308 16.0683 47.3842 16.74C47.4101 16.7892 47.4347 16.8384 47.458 16.8875C47.4891 16.9523 47.4619 16.9833 47.3959 16.9859C47.3596 16.9872 47.3221 16.9872 47.2858 16.9872C46.9234 16.9872 46.5622 16.9872 46.1997 16.9872L46.1984 16.9898ZM41.1043 13.0813C41.6222 13.0813 42.1387 13.0813 42.6565 13.0813C42.8145 13.0813 42.8196 13.0709 42.7497 12.9272C42.4688 12.3461 42.1866 11.7663 41.907 11.1852C41.7697 10.9005 41.6377 10.6145 41.5018 10.3297C41.3891 10.0942 41.2739 9.85995 41.1613 9.62569C41.1367 9.57392 41.1147 9.57263 41.0888 9.62311C41.0785 9.64381 41.0681 9.66452 41.059 9.68523C40.8674 10.0851 40.6758 10.4863 40.483 10.885C40.149 11.5722 39.815 12.2594 39.4797 12.9454C39.4175 13.0735 39.4214 13.0813 39.5664 13.0813C40.0791 13.0813 40.5917 13.0813 41.1043 13.0813Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M71.4204 23.2448C71.4204 23.725 71.4269 24.2064 71.4178 24.6866C71.4126 24.97 71.3945 25.2522 71.3116 25.5278C71.1395 26.0986 70.7459 26.4014 70.1673 26.4635C69.8436 26.4985 69.5161 26.4907 69.2093 26.3497C68.8623 26.1905 68.6423 25.9239 68.5568 25.5511C68.5322 25.4424 68.5504 25.423 68.6643 25.4178C68.7096 25.4152 68.7562 25.4191 68.8015 25.4178C68.8805 25.4139 68.9284 25.445 68.9581 25.5214C69.1251 25.9446 69.4604 26.1038 69.8915 26.1076C69.9834 26.1076 70.0753 26.1089 70.166 26.096C70.5919 26.03 70.8547 25.7867 70.9608 25.366C71.0255 25.1059 71.0385 24.8419 71.0333 24.5753C71.0333 24.5533 71.0411 24.5235 71.0152 24.5157C70.9893 24.508 70.9802 24.5377 70.966 24.5546C70.3537 25.3104 69.1536 25.1874 68.7044 24.3048C68.338 23.5852 68.3678 22.8462 68.764 22.1486C69.028 21.684 69.4643 21.4679 70.0067 21.4821C70.21 21.4873 70.408 21.5119 70.5945 21.5999C70.7291 21.6633 70.8482 21.7448 70.9466 21.8574C70.966 21.8794 70.9776 21.9273 71.0152 21.9105C71.0476 21.8975 71.032 21.8535 71.0372 21.8225C71.0437 21.7824 71.0488 21.7409 71.054 21.6995C71.0618 21.6413 71.0566 21.5714 71.1343 21.5585C71.2197 21.5429 71.3103 21.5261 71.3867 21.5792C71.4385 21.6141 71.4217 21.6788 71.423 21.7332C71.423 22.2366 71.423 22.7401 71.423 23.2435H71.4204V23.2448ZM71.0333 23.2254C71.045 23.0209 71.0268 22.7983 70.9725 22.5796C70.8754 22.1875 70.6424 21.9325 70.2307 21.8678C70.1452 21.8548 70.0572 21.8561 69.9705 21.8523C69.5083 21.8315 69.1717 22.0982 69.0151 22.4968C68.8326 22.9588 68.817 23.4338 68.9724 23.9075C69.0733 24.2142 69.2611 24.4562 69.5653 24.5921C69.7646 24.6814 69.9705 24.6866 70.1841 24.6529C70.5284 24.5986 70.7744 24.4226 70.9064 24.0951C71.0165 23.822 71.0411 23.5373 71.0333 23.2254Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M36.571 25.0413C36.1269 25.0413 35.6816 25.0413 35.2376 25.0413C35.0719 25.0413 35.0576 25.0284 35.0576 24.8602C35.0576 23.3679 35.0576 21.8757 35.0576 20.3822C35.0576 20.2062 35.068 20.1971 35.2453 20.1971C36.1204 20.1971 36.9956 20.1971 37.8707 20.1971C37.9315 20.1971 38.0131 20.1712 38.0403 20.2476C38.0739 20.3433 38.0739 20.4456 38.0351 20.5427C38.0221 20.575 37.9846 20.5802 37.9522 20.5828C37.9069 20.5854 37.8603 20.5867 37.815 20.5867C37.0952 20.5867 36.3768 20.5867 35.657 20.5867C35.4874 20.5867 35.4758 20.597 35.4758 20.7653C35.4758 21.2144 35.4758 21.6621 35.4758 22.1112C35.4758 22.2782 35.4861 22.286 35.6583 22.2873C36.3405 22.2873 37.024 22.2873 37.7063 22.2885C37.7749 22.2885 37.8681 22.2562 37.9056 22.3377C37.9458 22.4257 37.9315 22.5267 37.9121 22.6212C37.9031 22.6626 37.8642 22.6729 37.8254 22.6755C37.7801 22.6781 37.7335 22.6781 37.6882 22.6781C37.015 22.6781 36.3405 22.6781 35.6674 22.6781C35.4809 22.6781 35.4758 22.6833 35.4758 22.8722C35.4758 23.4029 35.4758 23.9348 35.4758 24.4654C35.4758 24.6427 35.4835 24.6505 35.6622 24.6505C36.4091 24.6505 37.1561 24.6505 37.9031 24.6505C37.9484 24.6505 37.995 24.6505 38.0403 24.6531C38.0675 24.6531 38.0998 24.6544 38.1167 24.679C38.1788 24.7721 38.1179 24.864 38.1102 24.9572C38.105 25.0168 38.0558 25.0375 38.0001 25.0401C37.9587 25.0413 37.9173 25.0426 37.8759 25.0426C37.4409 25.0426 37.0059 25.0426 36.5697 25.0426L36.571 25.0413Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M59.7618 23.5967C59.7618 23.8711 59.7618 24.1467 59.7618 24.4211C59.7618 24.5583 59.7644 24.6955 59.7838 24.8327C59.8097 25.019 59.7838 25.0423 59.6013 25.0423C59.5832 25.0423 59.565 25.041 59.5469 25.0423C59.4628 25.0449 59.4239 24.9996 59.4084 24.922C59.389 24.8301 59.4084 24.7304 59.3501 24.6476C59.2854 24.711 59.2233 24.777 59.156 24.8378C58.9605 25.0151 58.721 25.0811 58.466 25.1135C58.1812 25.1497 57.9067 25.1174 57.6491 24.9906C57.2776 24.8081 57.0704 24.3913 57.1054 23.9772C57.1468 23.4918 57.4187 23.2149 57.8588 23.0686C58.0905 22.991 58.3326 22.9599 58.576 22.9535C58.7961 22.947 59.0161 22.9483 59.2362 22.9522C59.3359 22.9535 59.3786 22.9133 59.3747 22.8137C59.3696 22.6856 59.3773 22.5574 59.3579 22.4306C59.3087 22.12 59.1249 21.9414 58.8245 21.878C58.6071 21.8314 58.3883 21.834 58.1708 21.8844C57.9468 21.9349 57.798 22.0669 57.7177 22.2831C57.6349 22.5031 57.6206 22.5069 57.3889 22.4914C57.2944 22.4849 57.2685 22.4422 57.2866 22.3581C57.3552 22.0488 57.5041 21.7977 57.7928 21.6424C58.0258 21.5182 58.2782 21.4806 58.5372 21.4858C58.7287 21.4897 58.9203 21.5026 59.1068 21.5661C59.4679 21.689 59.6699 21.944 59.7372 22.3115C59.7579 22.4241 59.767 22.538 59.7657 22.6532C59.7631 22.969 59.7657 23.2848 59.7657 23.6019L59.7618 23.5967ZM58.8957 23.2861C58.8 23.2861 58.7029 23.2809 58.6071 23.2861C58.374 23.3003 58.141 23.321 57.9222 23.4168C57.6336 23.5436 57.4886 23.7895 57.5106 24.1105C57.5326 24.4133 57.7203 24.6411 58.0154 24.7239C58.2381 24.7861 58.4608 24.7667 58.6809 24.711C58.9903 24.6321 59.1909 24.4366 59.2854 24.1312C59.3592 23.8918 59.376 23.6459 59.3708 23.3974C59.3696 23.3158 59.3294 23.2822 59.2505 23.2835C59.1314 23.2848 59.0123 23.2835 58.8932 23.2835L58.8957 23.2861Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M51.5615 22.4888C51.5615 21.7149 51.5615 20.9422 51.5615 20.1683C51.5615 19.9392 51.6392 19.8797 51.8657 19.9314C51.9175 19.9431 51.9473 19.9703 51.9486 20.0246C51.9499 20.066 51.9512 20.1075 51.9512 20.1476C51.9512 20.7157 51.9512 21.2826 51.9512 21.8508C51.9512 21.8831 51.9486 21.9155 51.9512 21.9465C51.9538 21.9711 51.9369 22.0087 51.9719 22.0177C51.9939 22.0242 52.0056 21.9918 52.0198 21.9737C52.3641 21.5259 52.8302 21.4146 53.3571 21.5117C53.8529 21.6036 54.1713 21.9957 54.2322 22.5432C54.2503 22.7075 54.2594 22.8706 54.2594 23.0363C54.2568 23.6407 54.2594 24.2451 54.2581 24.8495C54.2581 24.9116 54.2775 24.9918 54.205 25.0203C54.1105 25.0578 54.0069 25.054 53.9098 25.0203C53.8762 25.0087 53.8723 24.9711 53.871 24.9388C53.8697 24.8974 53.8684 24.8559 53.8684 24.8158C53.8684 24.1933 53.8684 23.5708 53.8684 22.9483C53.8684 22.7826 53.8477 22.6195 53.8037 22.4603C53.6962 22.0786 53.4296 21.8637 53.0308 21.8611C52.5091 21.8572 52.1298 22.0734 52.0004 22.6687C51.968 22.8163 51.9525 22.9664 51.9525 23.1178C51.9538 23.6899 51.9538 24.2619 51.9525 24.8339C51.9525 24.8986 51.9823 24.9867 51.9072 25.0216C51.8153 25.063 51.7104 25.0527 51.612 25.0255C51.5771 25.0164 51.5693 24.9802 51.5667 24.9465C51.5641 24.9012 51.5628 24.8546 51.5628 24.8093C51.5628 24.0354 51.5628 23.2628 51.5628 22.4888H51.5615Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M62.9167 23.3651C62.9167 22.9535 62.9167 22.5407 62.9167 22.1291C62.9167 21.9919 62.9206 21.8547 62.9024 21.7176C62.8856 21.5868 62.9193 21.5558 63.0526 21.5506C63.085 21.5506 63.1173 21.5506 63.1484 21.5506C63.2364 21.5506 63.283 21.5894 63.2869 21.6813C63.2921 21.7823 63.3128 21.8806 63.3089 21.9829C63.3089 22.0036 63.3012 22.0359 63.3258 22.0424C63.3517 22.0502 63.3594 22.0178 63.3711 21.9997C63.5432 21.7292 63.7853 21.561 64.1012 21.5092C64.4132 21.4574 64.7226 21.46 65.0126 21.6153C65.2313 21.7318 65.3893 21.9052 65.4786 22.1356C65.5537 22.3284 65.5938 22.5303 65.5938 22.74C65.5925 23.4402 65.5938 24.1416 65.5925 24.8418C65.5925 24.9065 65.621 24.9945 65.5395 25.0243C65.4488 25.0579 65.3504 25.0502 65.2559 25.0256C65.221 25.0165 65.2106 24.9829 65.208 24.9492C65.2055 24.9039 65.2042 24.8573 65.2042 24.812C65.2042 24.1572 65.2042 23.5023 65.2042 22.8474C65.2042 22.7283 65.1964 22.6106 65.1705 22.4928C65.0644 22.0036 64.7213 21.825 64.2695 21.8638C63.8216 21.9013 63.5407 22.1524 63.4034 22.573C63.3452 22.7529 63.3076 22.9354 63.3089 23.1257C63.3102 23.6848 63.3089 24.2426 63.3089 24.8017C63.3089 25.032 63.2326 25.0903 63.0034 25.0372C62.9516 25.0256 62.9219 24.9984 62.9206 24.944C62.9193 24.8987 62.918 24.8522 62.918 24.8069C62.918 24.3254 62.918 23.8452 62.918 23.3638L62.9167 23.3651Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M47.5209 21.4834C48.097 21.4977 48.5397 21.8225 48.7093 22.401C48.7197 22.436 48.7275 22.4722 48.7339 22.5085C48.7482 22.5848 48.7158 22.6211 48.6394 22.6224C48.5941 22.6224 48.5475 22.6211 48.5022 22.6236C48.4051 22.6301 48.3533 22.5874 48.3223 22.4942C48.2472 22.2716 48.1229 22.0814 47.9093 21.9662C47.3902 21.684 46.7494 21.8898 46.4943 22.445C46.2251 23.03 46.2303 23.6318 46.5202 24.2091C46.8322 24.8316 47.5403 24.8769 47.9624 24.5883C48.1255 24.477 48.2148 24.3152 48.2977 24.1444C48.3248 24.0887 48.3326 24.011 48.4077 23.9968C48.5022 23.98 48.6006 23.9774 48.6951 23.9981C48.7508 24.0098 48.7365 24.0628 48.7236 24.1016C48.5592 24.6012 48.264 24.983 47.7035 25.0904C46.9086 25.2431 46.2536 24.8562 46.0154 24.0835C45.8069 23.4066 45.8445 22.7401 46.2277 22.1267C46.506 21.6789 46.9306 21.4744 47.5209 21.4821V21.4834Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M43.3408 25.0426C43.2088 25.0737 43.1207 24.9974 43.0392 24.8731C42.7725 24.468 42.4955 24.0694 42.221 23.6682C42.1498 23.5634 42.1459 23.5647 42.0734 23.6708C41.7964 24.081 41.5207 24.4913 41.2436 24.9016C41.1608 25.0245 40.9135 25.097 40.7828 25.0336C40.7142 24.9999 40.7556 24.9572 40.7802 24.921C40.9278 24.7062 41.0779 24.4926 41.2268 24.2778C41.4417 23.9697 41.6553 23.6604 41.8728 23.355C41.9246 23.2825 41.9026 23.2307 41.8598 23.1699C41.6126 22.8127 41.3653 22.4542 41.1193 22.0957C41.0235 21.9559 40.9265 21.8175 40.8332 21.6764C40.7776 21.5936 40.7918 21.5612 40.8889 21.5534C40.9666 21.547 41.0443 21.5509 41.1219 21.5534C41.2061 21.556 41.2488 21.6169 41.2902 21.679C41.5414 22.0556 41.7938 22.4335 42.0462 22.8101C42.0618 22.8334 42.076 22.8567 42.0916 22.8787C42.155 22.9654 42.1666 22.9667 42.2262 22.8813C42.4191 22.6082 42.6107 22.3339 42.8023 22.0608C42.889 21.9365 42.9744 21.8123 43.0612 21.6893C43.1492 21.5651 43.3745 21.5017 43.5156 21.5599C43.5881 21.5897 43.5492 21.6337 43.5246 21.6686C43.4301 21.8032 43.3343 21.9378 43.2372 22.0711C42.9848 22.4206 42.735 22.77 42.4786 23.1156C42.4113 23.2062 42.4061 23.2786 42.4722 23.3731C42.81 23.8571 43.1427 24.3464 43.4767 24.833C43.4845 24.8446 43.4936 24.855 43.5 24.8666C43.5233 24.9132 43.5894 24.9533 43.5557 25.0103C43.5233 25.0634 43.4547 25.0349 43.4016 25.0414C43.3887 25.0427 43.3745 25.0414 43.3382 25.0414L43.3408 25.0426Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_9767">
          <rect
            className="rect"
            fill="white"
            height="26.0914"
            transform="translate(0.105469 0.691895)"
            width="77.0623"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
