/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBitmexWhite1 = ({ className }) => {
  return (
    <svg
      className={`logo-bitmex-white-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 90 16"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_10210)">
        <path
          className="path-3"
          d="M4.1781 1.38916L0.742188 15.166H4.18548L6.33339 6.55226H9.78038L11.0684 1.38916H7.6214H4.1781Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M17.9553 1.38916H14.512L13.224 6.55226L12.3641 9.99925H8.92082L7.63281 15.166H11.0761H14.5194L16.6673 6.55226L17.9553 1.38916Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M36.5415 2.22326C36.5442 2.35105 36.5192 2.47791 36.4682 2.59513C36.4173 2.71234 36.3415 2.81714 36.2462 2.90233C36.073 3.07272 35.8397 3.16821 35.5967 3.16821C35.3537 3.16821 35.1204 3.07272 34.9471 2.90233C34.7702 2.73255 34.6671 2.49997 34.6602 2.25482C34.6533 2.00967 34.7431 1.77165 34.9102 1.59218L34.9545 1.54421C35.1278 1.37381 35.361 1.27832 35.604 1.27832C35.8471 1.27832 36.0803 1.37381 36.2536 1.54421C36.3489 1.62896 36.4242 1.73386 36.474 1.85131C36.5238 1.96876 36.5469 2.09581 36.5415 2.22326ZM21.4102 1.3855H27.1084C28.6843 1.3855 29.9206 1.83944 30.7178 2.60708C31.0065 2.89782 31.2337 3.24372 31.3859 3.62415C31.538 4.00459 31.6121 4.41177 31.6035 4.82142V4.86203C31.6035 6.65565 30.4963 7.58198 29.4187 8.05068C31.0536 8.54521 32.3711 9.49 32.3711 11.3722V11.4128C32.3711 13.7748 30.3856 15.1735 27.3704 15.1735H21.4102V1.3855ZM26.8427 7.5303C28.7101 7.5303 30.0129 6.68146 30.0129 5.05761V5.02068C30.0129 3.65886 28.9278 2.77312 26.946 2.77312H22.9491V7.52657L26.8427 7.5303ZM27.3926 13.771C29.4814 13.771 30.7805 12.8484 30.7805 11.3021V11.2651C30.7805 9.76675 29.5183 8.89947 27.1158 8.89947H22.9491V13.771L27.3926 13.771ZM34.8364 4.98749H36.3569V15.1698H34.8364V4.98749ZM40.3723 11.9405V6.32717H38.0694V4.99856H40.3723V1.3855H41.8928V4.99856H46.0742V6.32717H41.9076V11.7449C41.9076 13.1141 42.5534 13.9851 43.7861 13.9593C44.5274 13.9738 45.2648 13.8486 45.9598 13.5902V14.9151C45.1594 15.244 44.296 15.3915 43.4318 15.3469C41.5939 15.3063 40.387 14.0109 40.387 11.9258L40.3723 11.9405ZM48.2775 1.3855H51.5473L55.1714 7.2129L58.7919 1.3855H62.0617V15.1698H59.0539V6.17217L55.1751 12.0771H55.0939L51.2558 6.24967V15.1808H48.2775V1.3855ZM64.7595 1.3855H75.1595V4.0833H67.7784V6.88075H73.9232V9.57855H67.7747V12.472H75.2555V15.1698H64.7595V1.3855ZM80.9279 8.15769L76.3184 1.3855H79.865L82.7584 5.85477L85.674 1.3855H89.121L84.5188 8.12078L89.3165 15.1698H85.7736L82.6735 10.4495L79.5624 15.1771H76.1154L80.9279 8.15769Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_10210">
          <rect
            className="rect"
            fill="white"
            height="14.9321"
            transform="translate(0.320312 0.850586)"
            width="89.4181"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
