/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoAceWhite6 = ({ className }) => {
  return (
    <svg
      className={`logo-ace-white-6 ${className}`}
      fill="none"
      height="33"
      viewBox="0 0 94 33"
      width="94"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_271_8649)">
        <path
          className="path-3"
          d="M20.2253 0.509766C20.2915 0.703403 20.4237 0.857684 20.5293 1.02613C21.6032 2.73896 22.6819 4.44864 23.7621 6.15832C24.8345 7.85856 25.91 9.55721 26.9824 11.2574C28.0374 12.9293 29.0893 14.6028 30.1428 16.2763C30.9475 17.553 31.7553 18.8282 32.56 20.105C33.4277 21.4809 34.2922 22.8568 35.1598 24.2328C35.9645 25.5095 36.7723 26.7847 37.577 28.0614C38.421 29.398 39.2651 30.7361 40.1075 32.0743C40.1406 32.1262 40.1658 32.1813 40.1942 32.2364H18.2522C18.2916 32.1262 18.3562 32.0271 18.4144 31.9263C19.0443 30.8369 19.6773 29.7491 20.3072 28.6597C21.0095 27.4443 21.7103 26.229 22.411 25.0136C22.7701 24.3918 23.1259 23.7668 23.4881 23.1481C23.5511 23.0394 23.5417 22.9623 23.474 22.8615C23.3023 22.6081 23.1417 22.3468 22.9779 22.087C22.0756 20.6544 21.1733 19.2218 20.271 17.7892C20.1923 17.6632 20.1718 17.6632 20.0915 17.7892C19.7435 18.3402 19.3955 18.8912 19.0475 19.4422C18.5467 20.2325 18.0459 21.0228 17.5468 21.8146C16.887 22.86 16.2303 23.9085 15.5705 24.9538C15.0146 25.8354 14.4572 26.7138 13.9013 27.5954C13.2211 28.6738 12.5392 29.7522 11.8621 30.8322C11.5692 31.2997 11.2621 31.7579 10.9897 32.2364C7.42142 32.2364 3.85314 32.2364 0.284862 32.2333C0.242345 32.2333 0.141564 32.301 0.151012 32.1703C0.210851 32.1341 0.245494 32.0774 0.281712 32.0208C0.883249 31.0699 1.48321 30.1175 2.08475 29.1666C2.84533 27.9607 3.60591 26.7548 4.36807 25.5489C5.05464 24.461 5.74436 23.3748 6.43093 22.2854C7.25135 20.985 8.0702 19.6846 8.89062 18.3843C9.44649 17.5027 10.0039 16.6242 10.5598 15.7426C11.2322 14.6784 11.9015 13.6126 12.5738 12.5468C13.1171 11.6841 13.662 10.8229 14.2052 9.96023C14.8682 8.90861 15.528 7.85698 16.1909 6.80693C16.8004 5.84031 17.4098 4.87527 18.0192 3.90866C18.7325 2.77674 19.4443 1.6464 20.156 0.514488C20.1781 0.514488 20.2001 0.514488 20.2222 0.514488L20.2253 0.509766Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M93.8922 28.0882C92.9788 28.0882 92.0639 28.0898 91.1506 28.0913C91.1002 28.0913 91.0498 28.0929 90.9994 28.0913C90.8923 28.0882 90.8498 28.1338 90.8624 28.2456C90.8908 28.4833 90.9286 28.7195 91.0168 28.9446C91.3459 29.7837 92.2151 29.9805 92.8922 29.6027C93.0765 29.4987 93.1993 29.3319 93.2953 29.1445C93.3678 29.006 93.6371 28.9289 93.7709 29.0076C93.8465 29.0516 93.8119 29.1162 93.7914 29.1697C93.6875 29.4468 93.5221 29.6814 93.2922 29.8687C92.667 30.3756 91.6104 30.3536 91.0199 29.812C90.738 29.5523 90.5711 29.2185 90.4782 28.8501C90.2971 28.1228 90.3396 27.4144 90.6687 26.7343C91.2026 25.6323 92.6466 25.5614 93.3489 26.2069C93.6103 26.4462 93.7693 26.7516 93.8559 27.0932C93.867 27.1357 93.878 27.1798 93.889 27.2223V28.0913L93.8922 28.0882ZM92.1694 27.6537C92.5426 27.6537 92.9158 27.6553 93.2891 27.6537C93.4324 27.6537 93.4591 27.619 93.4434 27.4837C93.4198 27.2947 93.3757 27.1121 93.2953 26.9374C92.9741 26.2321 92.0088 26.0101 91.4104 26.5044C91.1034 26.7595 90.9853 27.1074 90.916 27.4805C90.8876 27.6364 90.9002 27.6537 91.0656 27.6537C91.4325 27.6553 91.801 27.6537 92.1679 27.6537H92.1694Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M82.1475 13.0281C82.1475 10.7155 82.1475 8.40445 82.1475 6.09181C82.1475 6.03042 82.1491 5.96902 82.1475 5.90762C82.1459 5.80214 82.1963 5.75334 82.3002 5.75649C82.3506 5.75649 82.401 5.75649 82.4514 5.75649C85.9079 5.75649 89.3659 5.75649 92.8224 5.75649C93.025 5.75649 93.1263 5.85724 93.1263 6.05875C93.1263 6.69319 93.1263 7.32921 93.1263 7.96365C93.1263 8.1683 93.0213 8.27063 92.8114 8.27063C90.3139 8.27063 87.8149 8.27063 85.3174 8.27063C85.1284 8.27063 85.0339 8.36509 85.0339 8.554C85.0339 9.56313 85.0339 10.5707 85.0339 11.5798C85.0339 11.754 85.1195 11.8411 85.2906 11.8411C87.4983 11.8411 89.7061 11.8411 91.9138 11.8411C92.1175 11.8411 92.2193 11.9435 92.2193 12.1481C92.2193 12.8282 92.2193 13.5067 92.2193 14.1868C92.2193 14.4104 92.2067 14.4214 91.9815 14.4214C89.7848 14.4214 87.5881 14.4214 85.3914 14.4214C85.3363 14.4214 85.2796 14.4214 85.2245 14.4214C85.0481 14.4245 85.0339 14.4356 85.0339 14.6134C85.0339 15.5942 85.0339 16.575 85.0339 17.5558C85.0339 17.7856 85.0339 17.7856 85.2686 17.7856C87.7834 17.7856 90.2982 17.7856 92.813 17.7856C93.0219 17.7856 93.1263 17.8885 93.1263 18.0942C93.1263 18.7633 93.1263 19.4308 93.1263 20.0999C93.1263 20.3234 93.1153 20.3344 92.8885 20.3344C89.3864 20.3344 85.8858 20.3344 82.3837 20.3344C82.1585 20.3344 82.1475 20.3234 82.1475 20.0983C82.1475 17.7416 82.1475 15.3849 82.1475 13.0281Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M61.5361 13.1388C61.5172 11.9124 61.7849 10.823 62.2998 9.79654C62.6163 9.16682 63.0132 8.59063 63.4872 8.06639C64.1344 7.34851 64.8949 6.78334 65.7516 6.35041C66.6618 5.89072 67.627 5.62466 68.649 5.54752C69.9261 5.45149 71.1481 5.66245 72.3228 6.15677C73.1621 6.51099 73.9133 7.00374 74.5778 7.63031C75.0785 8.1026 75.5226 8.62211 75.8738 9.2172C75.973 9.38565 75.9714 9.39824 75.7935 9.46908C75.0061 9.78079 74.2172 10.0894 73.4298 10.4042C73.3149 10.4499 73.2472 10.4231 73.1763 10.3271C72.6787 9.64698 72.0661 9.09598 71.3197 8.70083C70.7166 8.38125 70.0773 8.18918 69.3876 8.16557C68.1687 8.12306 67.1027 8.51979 66.1893 9.30378C65.1989 10.1539 64.602 11.2339 64.454 12.539C64.2887 13.992 64.7438 15.2515 65.6949 16.344C66.4098 17.1658 67.3074 17.6932 68.3766 17.8994C69.6316 18.1419 70.7749 17.8475 71.8205 17.1312C72.3512 16.7691 72.7968 16.3204 73.1684 15.7993C73.2487 15.686 73.3228 15.6702 73.4472 15.7206C74.2282 16.0339 75.0124 16.3409 75.795 16.651C75.9698 16.7203 75.9746 16.7392 75.8769 16.9045C75.1478 18.1277 74.1353 19.0502 72.8818 19.7177C72.0252 20.1743 71.1119 20.4545 70.1497 20.56C68.6774 20.7221 67.2601 20.5112 65.9169 19.8673C65.0477 19.4501 64.2682 18.9085 63.6116 18.2048C62.4494 16.9627 61.7377 15.5065 61.5644 13.8015C61.5392 13.5559 61.5392 13.3119 61.5361 13.1403V13.1388Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M56.2197 20.3344C55.7788 20.3344 55.3379 20.3281 54.8985 20.3375C54.7301 20.3422 54.6371 20.2887 54.5631 20.1344C54.2561 19.5031 53.9333 18.8797 53.6246 18.2484C53.5569 18.1099 53.4734 18.0548 53.316 18.0564C51.1366 18.0611 48.9556 18.0611 46.7762 18.0564C46.614 18.0564 46.5243 18.1099 46.455 18.2563C46.1558 18.8797 45.8424 19.4953 45.5448 20.1187C45.4708 20.2745 45.3763 20.3407 45.1984 20.3391C44.3118 20.3312 43.4253 20.3359 42.5387 20.3359C42.4946 20.3359 42.4489 20.3359 42.4048 20.3312C42.3387 20.3249 42.3182 20.2871 42.3434 20.2257C42.3608 20.1848 42.3812 20.1455 42.4017 20.1045C43.2016 18.5743 44 17.0441 44.8015 15.5123C45.1606 14.8259 45.5212 14.1411 45.8786 13.4547C46.7006 11.8804 47.5226 10.3046 48.3462 8.7287C48.7178 8.01712 49.0895 7.30712 49.4611 6.59711C49.5792 6.37041 49.702 6.14529 49.817 5.91544C49.8658 5.81626 49.9367 5.7832 50.0437 5.7832C50.1555 5.7832 50.2311 5.81311 50.2847 5.91859C50.5256 6.38931 50.7744 6.85687 51.0201 7.32601C51.6326 8.502 52.2436 9.678 52.8562 10.854C53.3711 11.8411 53.8876 12.8282 54.4041 13.8152C55.0592 15.0684 55.7142 16.3231 56.3709 17.5762C56.7992 18.3948 57.2323 19.2135 57.6622 20.0305C57.6936 20.0904 57.7236 20.1502 57.7519 20.21C57.7897 20.2887 57.7566 20.3265 57.6763 20.3297C57.6322 20.3312 57.5866 20.3312 57.5425 20.3312C57.1016 20.3312 56.6622 20.3312 56.2213 20.3312L56.2197 20.3344ZM50.0233 15.58C50.6531 15.58 51.2815 15.58 51.9113 15.58C52.1034 15.58 52.1097 15.5674 52.0247 15.3927C51.683 14.6858 51.3397 13.9805 50.9996 13.2737C50.8327 12.9273 50.672 12.5794 50.5067 12.2331C50.3697 11.9466 50.2296 11.6616 50.0926 11.3767C50.0626 11.3137 50.0359 11.3121 50.0044 11.3735C49.9918 11.3987 49.9792 11.4239 49.9681 11.4491C49.7351 11.9355 49.502 12.4236 49.2674 12.9084C48.8611 13.7444 48.4549 14.5803 48.047 15.4147C47.9714 15.5706 47.9762 15.58 48.1525 15.58C48.7761 15.58 49.3997 15.58 50.0233 15.58Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M86.8997 27.9433C86.8997 28.5274 86.9076 29.113 86.8966 29.6971C86.8903 30.0419 86.8682 30.3851 86.7674 30.7204C86.558 31.4146 86.0793 31.783 85.3754 31.8586C84.9817 31.9011 84.5833 31.8916 84.2101 31.72C83.7881 31.5264 83.5204 31.2021 83.4165 30.7487C83.3866 30.6165 83.4086 30.5929 83.5472 30.5866C83.6023 30.5834 83.659 30.5881 83.7141 30.5866C83.8102 30.5818 83.8684 30.6196 83.9046 30.7125C84.1078 31.2273 84.5156 31.4209 85.04 31.4257C85.1518 31.4257 85.2636 31.4272 85.3738 31.4115C85.8919 31.3312 86.2116 31.0352 86.3407 30.5236C86.4194 30.2072 86.4352 29.886 86.4289 29.5617C86.4289 29.5349 86.4383 29.4987 86.4068 29.4893C86.3753 29.4798 86.3643 29.516 86.347 29.5365C85.6022 30.4559 84.1424 30.3063 83.596 29.2327C83.1504 28.3574 83.1866 27.4584 83.6684 26.6099C83.9897 26.0447 84.5203 25.7818 85.1801 25.7991C85.4274 25.8054 85.6683 25.8354 85.8951 25.9424C86.0588 26.0195 86.2037 26.1187 86.3234 26.2557C86.347 26.2824 86.3612 26.3407 86.4068 26.3202C86.4462 26.3045 86.4273 26.251 86.4336 26.2132C86.4415 26.1644 86.4478 26.114 86.4541 26.0636C86.4635 25.9928 86.4572 25.9078 86.5517 25.892C86.6556 25.8731 86.7659 25.8527 86.8588 25.9172C86.9218 25.9597 86.9013 26.0384 86.9029 26.1046C86.9029 26.717 86.9029 27.3294 86.9029 27.9418H86.8997V27.9433ZM86.4289 27.9197C86.4431 27.671 86.421 27.4002 86.3549 27.1341C86.2368 26.6571 85.9533 26.347 85.4526 26.2683C85.3486 26.2525 85.2416 26.2541 85.136 26.2494C84.5739 26.2242 84.1645 26.5485 83.9739 27.0334C83.7519 27.5954 83.733 28.1732 83.9219 28.7494C84.0448 29.1225 84.2731 29.4169 84.6432 29.5822C84.8857 29.6908 85.1361 29.6971 85.3959 29.6562C85.8147 29.59 86.1139 29.3759 86.2746 28.9776C86.4084 28.6455 86.4383 28.2991 86.4289 27.9197Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M44.5088 30.1282C43.9687 30.1282 43.427 30.1282 42.8869 30.1282C42.6853 30.1282 42.668 30.1124 42.668 29.9078C42.668 28.0926 42.668 26.2775 42.668 24.4607C42.668 24.2466 42.6806 24.2356 42.8963 24.2356C43.9608 24.2356 45.0253 24.2356 46.0898 24.2356C46.1638 24.2356 46.263 24.2041 46.2961 24.297C46.337 24.4135 46.337 24.5379 46.2898 24.6559C46.274 24.6953 46.2284 24.7016 46.189 24.7047C46.1339 24.7079 46.0772 24.7095 46.0221 24.7095C45.1466 24.7095 44.2726 24.7095 43.3971 24.7095C43.1908 24.7095 43.1766 24.7221 43.1766 24.9267C43.1766 25.473 43.1766 26.0177 43.1766 26.564C43.1766 26.7671 43.1892 26.7765 43.3986 26.7781C44.2285 26.7781 45.0599 26.7781 45.8898 26.7797C45.9733 26.7797 46.0867 26.7403 46.1323 26.8395C46.1811 26.9465 46.1638 27.0693 46.1402 27.1843C46.1292 27.2346 46.0819 27.2472 46.0347 27.2504C45.9796 27.2535 45.9229 27.2535 45.8678 27.2535C45.0489 27.2535 44.2285 27.2535 43.4097 27.2535C43.1829 27.2535 43.1766 27.2598 43.1766 27.4897C43.1766 28.1351 43.1766 28.7822 43.1766 29.4276C43.1766 29.6433 43.186 29.6527 43.4034 29.6527C44.312 29.6527 45.2206 29.6527 46.1292 29.6527C46.1843 29.6527 46.241 29.6527 46.2961 29.6559C46.3292 29.6559 46.3685 29.6575 46.389 29.6874C46.4646 29.8007 46.3906 29.9125 46.3811 30.0259C46.3748 30.0983 46.315 30.1235 46.2473 30.1266C46.1969 30.1282 46.1465 30.1298 46.0961 30.1298C45.567 30.1298 45.0379 30.1298 44.5072 30.1298L44.5088 30.1282Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M72.7184 28.3715C72.7184 28.7052 72.7184 29.0405 72.7184 29.3743C72.7184 29.5412 72.7215 29.708 72.7451 29.8749C72.7766 30.1016 72.7451 30.1299 72.5231 30.1299C72.501 30.1299 72.479 30.1284 72.457 30.1299C72.3546 30.1331 72.3074 30.078 72.2885 29.9835C72.2648 29.8718 72.2885 29.7505 72.2176 29.6498C72.1389 29.7269 72.0633 29.8072 71.9814 29.8812C71.7436 30.0969 71.4523 30.1772 71.1421 30.2165C70.7956 30.2606 70.4618 30.2212 70.1484 30.067C69.6965 29.845 69.4445 29.3381 69.4871 28.8343C69.5375 28.2439 69.8681 27.907 70.4035 27.7291C70.6854 27.6347 70.9799 27.5969 71.2759 27.589C71.5436 27.5812 71.8113 27.5827 72.079 27.5875C72.2003 27.589 72.2522 27.5402 72.2475 27.419C72.2412 27.2632 72.2507 27.1073 72.2271 26.953C72.1672 26.5752 71.9436 26.3579 71.5783 26.2808C71.3137 26.2241 71.0476 26.2273 70.783 26.2887C70.5106 26.3501 70.3295 26.5106 70.2319 26.7735C70.1311 27.0412 70.1138 27.0459 69.8319 27.027C69.717 27.0191 69.6855 26.9672 69.7075 26.8649C69.791 26.4886 69.9721 26.1832 70.3232 25.9943C70.6067 25.8431 70.9137 25.7975 71.2287 25.8038C71.4617 25.8085 71.6948 25.8242 71.9216 25.9014C72.3609 26.0509 72.6066 26.3611 72.6884 26.8082C72.7136 26.9451 72.7247 27.0837 72.7231 27.2238C72.7199 27.6079 72.7231 27.992 72.7231 28.3778L72.7184 28.3715ZM71.6649 27.9936C71.5484 27.9936 71.4302 27.9873 71.3137 27.9936C71.0303 28.0109 70.7468 28.0361 70.4807 28.1526C70.1295 28.3069 69.9532 28.606 69.9799 28.9964C70.0067 29.3648 70.235 29.6419 70.5941 29.7427C70.8649 29.8182 71.1358 29.7946 71.4035 29.7269C71.7798 29.6309 72.0239 29.3932 72.1389 29.0216C72.2286 28.7304 72.2491 28.4313 72.2428 28.129C72.2412 28.0298 72.1924 27.9889 72.0963 27.9905C71.9515 27.992 71.8066 27.9905 71.6617 27.9905L71.6649 27.9936Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M62.7441 27.0235C62.7441 26.0821 62.7441 25.1423 62.7441 24.2008C62.7441 23.9222 62.8386 23.8498 63.1142 23.9127C63.1772 23.9269 63.2134 23.96 63.215 24.0261C63.2166 24.0765 63.2181 24.1269 63.2181 24.1757C63.2181 24.8668 63.2181 25.5563 63.2181 26.2474C63.2181 26.2868 63.215 26.3261 63.2181 26.3639C63.2213 26.3938 63.2008 26.4395 63.2433 26.4505C63.2701 26.4584 63.2843 26.419 63.3016 26.397C63.7205 25.8523 64.2873 25.7169 64.9283 25.835C65.5314 25.9467 65.9187 26.4237 65.9927 27.0897C66.0148 27.2896 66.0258 27.488 66.0258 27.6895C66.0227 28.4247 66.0258 29.1599 66.0242 29.8951C66.0242 29.9706 66.0479 30.0682 65.9597 30.1029C65.8447 30.1485 65.7188 30.1438 65.6007 30.1029C65.5597 30.0887 65.555 30.043 65.5534 30.0037C65.5518 29.9533 65.5503 29.9029 65.5503 29.8541C65.5503 29.0969 65.5503 28.3397 65.5503 27.5824C65.5503 27.3809 65.5251 27.1826 65.4715 26.9889C65.3408 26.5245 65.0164 26.2632 64.5314 26.26C63.8968 26.2553 63.4354 26.5182 63.278 27.2424C63.2386 27.4218 63.2197 27.6045 63.2197 27.7887C63.2213 28.4845 63.2213 29.1803 63.2197 29.8762C63.2197 29.9549 63.2559 30.0619 63.1646 30.1044C63.0528 30.1548 62.9252 30.1422 62.8056 30.1092C62.763 30.0981 62.7536 30.0541 62.7504 30.0131C62.7473 29.958 62.7457 29.9014 62.7457 29.8463C62.7457 28.9048 62.7457 27.965 62.7457 27.0235H62.7441Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M76.5554 28.0897C76.5554 27.589 76.5554 27.0868 76.5554 26.5862C76.5554 26.4193 76.5601 26.2525 76.5381 26.0856C76.5176 25.9266 76.5585 25.8888 76.7207 25.8825C76.7601 25.8825 76.7995 25.8825 76.8373 25.8825C76.9444 25.8825 77.001 25.9297 77.0058 26.0415C77.0121 26.1643 77.0373 26.2839 77.0325 26.4083C77.0325 26.4335 77.0231 26.4729 77.053 26.4807C77.0845 26.4902 77.0939 26.4508 77.1081 26.4288C77.3176 26.0998 77.612 25.8951 77.9963 25.8321C78.3758 25.7692 78.7521 25.7723 79.1048 25.9612C79.371 26.1029 79.5631 26.3139 79.6717 26.5941C79.7631 26.8287 79.8119 27.0742 79.8119 27.3293C79.8103 28.181 79.8119 29.0342 79.8103 29.8859C79.8103 29.9646 79.845 30.0717 79.7457 30.1079C79.6355 30.1488 79.5158 30.1394 79.4009 30.1095C79.3584 30.0985 79.3458 30.0575 79.3426 30.0166C79.3395 29.9615 79.3379 29.9048 79.3379 29.8497C79.3379 29.0531 79.3379 28.2565 79.3379 27.4599C79.3379 27.3151 79.3285 27.1719 79.297 27.0286C79.1678 26.4335 78.7505 26.2163 78.201 26.2635C77.6561 26.3091 77.3144 26.6146 77.1475 27.1262C77.0766 27.345 77.031 27.567 77.0325 27.7984C77.0341 28.4785 77.0325 29.157 77.0325 29.8371C77.0325 30.1173 76.9396 30.1882 76.6609 30.1236C76.5979 30.1095 76.5617 30.0764 76.5601 30.0103C76.5585 29.9552 76.557 29.8985 76.557 29.8434C76.557 29.2578 76.557 28.6737 76.557 28.0881L76.5554 28.0897Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M57.829 25.8007C58.5298 25.818 59.0683 26.2131 59.2746 26.9168C59.2872 26.9593 59.2967 27.0034 59.3045 27.0475C59.3218 27.1404 59.2825 27.1845 59.1896 27.186C59.1345 27.186 59.0778 27.1845 59.0227 27.1876C58.9045 27.1955 58.8416 27.1435 58.8038 27.0302C58.7124 26.7594 58.5613 26.528 58.3014 26.3879C57.67 26.0447 56.8905 26.295 56.5803 26.9704C56.2527 27.6819 56.259 28.414 56.6118 29.1161C56.9913 29.8734 57.8526 29.9285 58.366 29.5774C58.5644 29.442 58.6731 29.2452 58.7738 29.0374C58.8069 28.9697 58.8164 28.8753 58.9077 28.8579C59.0226 28.8375 59.1423 28.8343 59.2573 28.8595C59.325 28.8737 59.3077 28.9382 59.2919 28.9855C59.0919 29.5931 58.7329 30.0575 58.0511 30.1882C57.0842 30.374 56.2874 29.9033 55.9976 28.9634C55.7441 28.1401 55.7898 27.3293 56.2559 26.5831C56.5945 26.0384 57.111 25.7896 57.829 25.7991V25.8007Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M52.7443 30.1297C52.5837 30.1675 52.4766 30.0746 52.3774 29.9234C52.053 29.4307 51.716 28.9458 51.3822 28.4578C51.2956 28.3303 51.2909 28.3318 51.2027 28.4609C50.8657 28.96 50.5303 29.459 50.1933 29.9581C50.0925 30.1076 49.7918 30.1958 49.6327 30.1186C49.5492 30.0777 49.5996 30.0258 49.6296 29.9817C49.8091 29.7204 49.9917 29.4606 50.1728 29.1993C50.4342 28.8246 50.6941 28.4483 50.9586 28.0768C51.0216 27.9886 50.9948 27.9257 50.9429 27.8517C50.6421 27.4172 50.3413 26.9811 50.0421 26.545C49.9256 26.375 49.8075 26.2065 49.6941 26.0349C49.6264 25.9342 49.6437 25.8948 49.7618 25.8854C49.8563 25.8775 49.9508 25.8822 50.0453 25.8854C50.1476 25.8885 50.1996 25.9625 50.25 26.0381C50.5555 26.4962 50.8626 26.9559 51.1696 27.414C51.1885 27.4424 51.2058 27.4707 51.2247 27.4975C51.3019 27.6029 51.3161 27.6045 51.3885 27.5006C51.6231 27.1684 51.8562 26.8347 52.0892 26.5025C52.1947 26.3514 52.2987 26.2002 52.4042 26.0507C52.5113 25.8996 52.7853 25.8224 52.9569 25.8933C53.0451 25.9295 52.9978 25.983 52.9679 26.0255C52.853 26.1892 52.7364 26.3529 52.6183 26.5151C52.3113 26.9402 52.0074 27.3652 51.6956 27.7855C51.6137 27.8957 51.6074 27.9839 51.6877 28.0988C52.0987 28.6876 52.5034 29.2827 52.9097 29.8746C52.9191 29.8888 52.9301 29.9014 52.938 29.9156C52.9664 29.9722 53.0467 30.021 53.0057 30.0903C52.9664 30.1549 52.8829 30.1202 52.8183 30.1281C52.8026 30.1297 52.7853 30.1281 52.7412 30.1281L52.7443 30.1297Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_271_8649">
          <rect
            className="rect"
            fill="white"
            height="31.7377"
            transform="translate(0.152344 0.509766)"
            width="93.739"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
