/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoBinanceWhite6 = ({ className }) => {
  return (
    <svg
      className={`logo-binance-white-6 ${className}`}
      fill="none"
      height="31"
      viewBox="0 0 155 31"
      width="155"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_7694)">
        <path
          className="path-3"
          d="M9.52934 13.0537L15.5353 7.05027L21.5438 13.0586L25.0365 9.56352L15.5353 0.0600586L6.03418 9.56107L9.52934 13.0537Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M0.0708008 15.5225L3.56468 12.0286L7.05856 15.5225L3.56468 19.0163L0.0708008 15.5225Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M9.52934 17.9897L15.5353 23.9957L21.5438 17.9873L25.039 21.4775L25.0365 21.48L15.5353 30.9834L6.03418 21.4849L6.0293 21.48L9.52934 17.9897Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M24.0063 15.5234L27.5002 12.0296L30.9941 15.5234L27.5002 19.0173L24.0063 15.5234Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M19.0792 15.5205L15.5352 11.9741L12.9145 14.5948L12.6116 14.8953L11.9912 15.5156L11.9863 15.5205L11.9912 15.5278L15.5352 19.0694L19.0792 15.523L19.0817 15.5205H19.0792Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M36.4453 7.55322H44.0462C45.9318 7.55322 47.3582 8.04171 48.3254 9.01867C48.6898 9.37956 48.9771 9.81072 49.1698 10.286C49.3625 10.7613 49.4567 11.2707 49.4465 11.7835V11.8299C49.4531 12.25 49.3946 12.6685 49.2731 13.0706C49.1649 13.4169 49.0078 13.7458 48.8066 14.0476C48.619 14.3322 48.3921 14.5888 48.1325 14.8097C47.8789 15.0279 47.6011 15.2164 47.3045 15.3714C48.1597 15.6693 48.9288 16.1725 49.5442 16.8369C50.0881 17.4556 50.36 18.3105 50.36 19.4014V19.4478C50.3755 20.1291 50.2272 20.8042 49.9277 21.4164C49.6375 21.9804 49.212 22.4638 48.6894 22.8232C48.0985 23.2194 47.4405 23.5049 46.7476 23.6659C45.9243 23.8611 45.0804 23.9554 44.2343 23.9467H36.4453V7.55322ZM43.2842 14.182C43.9418 14.2137 44.5962 14.0722 45.182 13.7716C45.4104 13.6382 45.5969 13.4435 45.7204 13.2095C45.8438 12.9756 45.8993 12.7117 45.8805 12.4478V12.4014C45.8926 12.1583 45.8432 11.916 45.7369 11.697C45.6306 11.478 45.4709 11.2894 45.2724 11.1485C44.8669 10.8603 44.2815 10.7162 43.5162 10.7162H39.96V14.182H43.2842ZM44.2441 20.7887C44.8993 20.8254 45.5514 20.6755 46.1248 20.3564C46.3481 20.2129 46.529 20.0121 46.6484 19.775C46.7678 19.5379 46.8215 19.2731 46.8038 19.0082V18.9618C46.8126 18.71 46.7595 18.4599 46.6492 18.2333C46.5389 18.0068 46.3748 17.8108 46.1712 17.6624C45.7511 17.3367 45.0721 17.1739 44.1342 17.1739H39.96V20.7887H44.2441Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M54.8882 7.55322H58.4957V23.9516H54.8882V7.55322Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M63.9546 7.55322H67.2812L74.9701 17.6453V7.55322H78.5312V23.9516H75.461L67.5206 13.5274V23.9516H63.9546V7.55322Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M89.4542 7.43604H92.7759L99.8029 23.9517H96.0366L94.537 20.2734H87.6028L86.1031 23.9517H82.4248L89.4542 7.43604ZM93.2498 17.0885L91.0662 11.7689L88.8924 17.0885H93.2498Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M103.706 7.56348H107.033L114.717 17.6556V7.56348H118.278V23.9619H115.208L107.267 13.5376V23.9619H103.706V7.56348Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M131.259 24.2402C130.109 24.25 128.969 24.0273 127.908 23.5856C126.908 23.173 126.002 22.5645 125.241 21.796C124.481 21.0276 123.882 20.1146 123.48 19.1111C123.055 18.0613 122.84 16.9387 122.847 15.8065V15.7601C122.828 13.5283 123.687 11.3784 125.238 9.7737C126.001 8.98987 126.915 8.36844 127.925 7.94677C129.023 7.4919 130.202 7.26504 131.391 7.27999C132.094 7.27474 132.796 7.33773 133.486 7.46806C134.077 7.57984 134.655 7.75254 135.211 7.98341C135.715 8.19556 136.194 8.46258 136.64 8.77964C137.074 9.08886 137.483 9.43361 137.861 9.81034L135.567 12.4579C134.981 11.9173 134.324 11.4595 133.613 11.0975C132.915 10.7638 132.15 10.5966 131.376 10.609C130.732 10.6021 130.095 10.7323 129.505 10.9909C128.915 11.2494 128.388 11.6305 127.957 12.1087C127.529 12.5856 127.195 13.1381 126.97 13.7377C126.735 14.3714 126.616 15.0426 126.621 15.7186V15.765C126.618 16.4399 126.737 17.1099 126.97 17.7433C127.192 18.3451 127.523 18.9007 127.947 19.3822C128.374 19.8663 128.901 20.2529 129.491 20.5159C130.081 20.7788 130.72 20.912 131.366 20.9063C132.189 20.9322 133.005 20.7552 133.743 20.3909C134.445 20.0081 135.095 19.5351 135.675 18.9841L137.971 21.3044C137.565 21.7444 137.127 22.1526 136.659 22.5256C136.2 22.8889 135.701 23.1994 135.172 23.4513C134.608 23.7166 134.014 23.9126 133.403 24.035C132.697 24.1765 131.979 24.2453 131.259 24.2402Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M142.228 7.55322H154.57V10.7626H145.777V14.0891H153.52V17.2985H145.789V20.7423H154.692V23.9516H142.235L142.228 7.55322Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_7694">
          <rect
            className="rect"
            fill="white"
            height="30.9235"
            transform="translate(0.0722656 0.0600586)"
            width="154.617"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
