/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoAceWhite2 = ({ className }) => {
  return (
    <svg
      className={`logo-ace-white-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 70 24"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_250_8168)">
        <path
          className="path-3"
          d="M15.7095 0.123047C15.7581 0.265298 15.8553 0.378637 15.9328 0.502384C16.7217 1.76067 17.5141 3.01665 18.3077 4.27262C19.0955 5.52166 19.8856 6.76954 20.6734 8.01858C21.4485 9.24679 22.2212 10.4762 22.9951 11.7055C23.5863 12.6435 24.1797 13.5803 24.7709 14.5182C25.4083 15.529 26.0434 16.5398 26.6808 17.5506C27.2719 18.4885 27.8653 19.4253 28.4565 20.3632C29.0765 21.3451 29.6966 22.3281 30.3155 23.3112C30.3398 23.3493 30.3583 23.3898 30.3791 23.4303H14.26C14.2889 23.3493 14.3363 23.2765 14.3792 23.2025C14.8419 22.4022 15.3069 21.603 15.7696 20.8027C16.2856 19.9099 16.8004 19.017 17.3152 18.1242C17.5789 17.6674 17.8404 17.2082 18.1064 16.7537C18.1527 16.6739 18.1458 16.6173 18.096 16.5432C17.9699 16.357 17.8519 16.1651 17.7316 15.9742C17.0688 14.9218 16.4059 13.8694 15.743 12.817C15.6852 12.7244 15.6702 12.7244 15.6112 12.817C15.3555 13.2217 15.0998 13.6265 14.8442 14.0313C14.4763 14.6119 14.1085 15.1924 13.7417 15.7742C13.257 16.5421 12.7746 17.3123 12.2899 18.0803C11.8816 18.7279 11.4721 19.3732 11.0637 20.0209C10.564 20.8131 10.0631 21.6053 9.56563 22.3987C9.35046 22.7422 9.12488 23.0787 8.92475 23.4303C6.3034 23.4303 3.68205 23.4303 1.0607 23.428C1.02947 23.428 0.95543 23.4777 0.962371 23.3817C1.00633 23.3551 1.03178 23.3135 1.05839 23.2719C1.50029 22.5733 1.94104 21.8736 2.38294 21.1751C2.94169 20.2892 3.50043 19.4033 4.06033 18.5174C4.5647 17.7183 5.07139 16.9203 5.57576 16.12C6.17846 15.1647 6.78001 14.2094 7.38271 13.2541C7.79107 12.6065 8.20058 11.9611 8.60894 11.3135C9.1029 10.5317 9.59455 9.74872 10.0885 8.96576C10.4876 8.33199 10.8879 7.69938 11.287 7.06561C11.774 6.29306 12.2587 5.5205 12.7457 4.74911C13.1934 4.03901 13.6411 3.33006 14.0888 2.61996C14.6128 1.78843 15.1357 0.958051 15.6586 0.126516C15.6748 0.126516 15.691 0.126516 15.7072 0.126516L15.7095 0.123047Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M69.8262 20.3827C69.1552 20.3827 68.4831 20.3839 67.8122 20.385C67.7751 20.385 67.7381 20.3862 67.7011 20.385C67.6224 20.3827 67.5912 20.4162 67.6005 20.4984C67.6213 20.673 67.6491 20.8465 67.7138 21.0118C67.9556 21.6283 68.5942 21.7728 69.0916 21.4953C69.227 21.4189 69.3172 21.2963 69.3878 21.1587C69.441 21.057 69.6388 21.0003 69.7371 21.0581C69.7926 21.0905 69.7672 21.1379 69.7522 21.1772C69.6758 21.3808 69.5543 21.5531 69.3854 21.6907C68.9262 22.0631 68.15 22.0469 67.7161 21.6491C67.5091 21.4583 67.3865 21.2131 67.3182 20.9425C67.1852 20.4081 67.2164 19.8877 67.4582 19.3881C67.8503 18.5785 68.9111 18.5265 69.4271 19.0007C69.6191 19.1765 69.736 19.4008 69.7996 19.6518C69.8077 19.683 69.8158 19.7154 69.8239 19.7466V20.385L69.8262 20.3827ZM68.5606 20.0635C68.8348 20.0635 69.109 20.0647 69.3831 20.0635C69.4884 20.0635 69.5081 20.0381 69.4965 19.9386C69.4791 19.7998 69.4467 19.6657 69.3878 19.5373C69.1518 19.0192 68.4426 18.8561 68.003 19.2192C67.7775 19.4066 67.6907 19.6622 67.6398 19.9363C67.619 20.0508 67.6282 20.0635 67.7497 20.0635C68.0192 20.0647 68.2899 20.0635 68.5595 20.0635H68.5606Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M61.1983 9.31959C61.1983 7.62067 61.1983 5.9229 61.1983 4.22398C61.1983 4.17888 61.1994 4.13377 61.1983 4.08867C61.1971 4.01118 61.2341 3.97533 61.3105 3.97764C61.3475 3.97764 61.3845 3.97764 61.4215 3.97764C63.9608 3.97764 66.5011 3.97764 69.0403 3.97764C69.1892 3.97764 69.2636 4.05166 69.2636 4.19969C69.2636 4.66577 69.2636 5.133 69.2636 5.59908C69.2636 5.74942 69.1865 5.8246 69.0322 5.8246C67.1975 5.8246 65.3617 5.8246 63.5269 5.8246C63.3881 5.8246 63.3187 5.89399 63.3187 6.03277C63.3187 6.7741 63.3187 7.51427 63.3187 8.25559C63.3187 8.38358 63.3816 8.44758 63.5073 8.44758C65.1291 8.44758 66.751 8.44758 68.3729 8.44758C68.5225 8.44758 68.5973 8.52275 68.5973 8.6731C68.5973 9.17271 68.5973 9.67117 68.5973 10.1708C68.5973 10.335 68.588 10.3431 68.4226 10.3431C66.8088 10.3431 65.1951 10.3431 63.5813 10.3431C63.5408 10.3431 63.4992 10.3431 63.4587 10.3431C63.3291 10.3454 63.3187 10.3535 63.3187 10.4842C63.3187 11.2047 63.3187 11.9252 63.3187 12.6457C63.3187 12.8146 63.3187 12.8146 63.4911 12.8146C65.3385 12.8146 67.186 12.8146 69.0334 12.8146C69.1869 12.8146 69.2636 12.8901 69.2636 13.0413C69.2636 13.5328 69.2636 14.0231 69.2636 14.5147C69.2636 14.6789 69.2555 14.687 69.0889 14.687C66.5162 14.687 63.9446 14.687 61.3718 14.687C61.2064 14.687 61.1983 14.6789 61.1983 14.5135C61.1983 12.7822 61.1983 11.0509 61.1983 9.31959Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M46.0573 9.40046C46.0434 8.49954 46.2401 7.69923 46.6184 6.94518C46.8509 6.48258 47.1424 6.05929 47.4906 5.67417C47.9661 5.1468 48.5248 4.73161 49.1541 4.41357C49.8228 4.07587 50.5319 3.88042 51.2827 3.82375C52.2209 3.7532 53.1185 3.90817 53.9815 4.27132C54.5981 4.53153 55.1499 4.89352 55.6381 5.35382C56.006 5.70077 56.3322 6.08242 56.5902 6.51958C56.663 6.64333 56.6619 6.65258 56.5312 6.70463C55.9528 6.93362 55.3732 7.16029 54.7948 7.3916C54.7103 7.42514 54.6606 7.40548 54.6085 7.33493C54.243 6.83531 53.793 6.43053 53.2446 6.14025C52.8016 5.90547 52.3319 5.76438 51.8252 5.74703C50.9298 5.71581 50.1467 6.00725 49.4757 6.58319C48.7481 7.20771 48.3097 8.00108 48.2009 8.95983C48.0794 10.0273 48.4138 10.9525 49.1125 11.7551C49.6377 12.3588 50.2971 12.7463 51.0825 12.8978C52.0045 13.0759 52.8444 12.8596 53.6125 12.3334C54.0024 12.0674 54.3297 11.7378 54.6027 11.355C54.6617 11.2717 54.7161 11.2601 54.8075 11.2971C55.3813 11.5273 55.9574 11.7528 56.5323 11.9806C56.6607 12.0315 56.6642 12.0454 56.5925 12.1668C56.0569 13.0655 55.313 13.7432 54.3922 14.2335C53.7629 14.5689 53.0919 14.7748 52.3851 14.8523C51.3035 14.9714 50.2624 14.8164 49.2756 14.3434C48.637 14.0369 48.0644 13.6391 47.582 13.1221C46.7283 12.2096 46.2054 11.1399 46.0782 9.88736C46.0596 9.70694 46.0596 9.52768 46.0573 9.40162V9.40046Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M42.1517 14.6867C41.8278 14.6867 41.5039 14.6821 41.1812 14.6891C41.0574 14.6925 40.9891 14.6532 40.9348 14.5399C40.7092 14.0761 40.472 13.6181 40.2453 13.1544C40.1956 13.0526 40.1342 13.0121 40.0186 13.0133C38.4175 13.0167 36.8153 13.0167 35.2143 13.0133C35.0951 13.0133 35.0292 13.0526 34.9783 13.1601C34.7585 13.6181 34.5283 14.0703 34.3097 14.5283C34.2553 14.6428 34.1859 14.6914 34.0552 14.6902C33.4039 14.6844 32.7526 14.6879 32.1013 14.6879C32.0689 14.6879 32.0354 14.6879 32.003 14.6844C31.9544 14.6798 31.9393 14.652 31.9579 14.6069C31.9706 14.5769 31.9856 14.548 32.0007 14.5179C32.5883 13.3938 33.1748 12.2696 33.7636 11.1443C34.0274 10.6401 34.2923 10.137 34.5549 9.63277C35.1588 8.47625 35.7626 7.31858 36.3676 6.16091C36.6407 5.63817 36.9137 5.11658 37.1867 4.59499C37.2734 4.42845 37.3637 4.26307 37.4481 4.09422C37.484 4.02136 37.536 3.99707 37.6147 3.99707C37.6968 3.99707 37.7524 4.01904 37.7917 4.09653C37.9687 4.44233 38.1515 4.78581 38.3319 5.13046C38.7819 5.99437 39.2308 6.85829 39.6808 7.72221C40.0591 8.44734 40.4385 9.17248 40.8179 9.89761C41.2992 10.8182 41.7804 11.7399 42.2628 12.6605C42.5774 13.2619 42.8956 13.8633 43.2114 14.4635C43.2345 14.5075 43.2565 14.5514 43.2773 14.5954C43.3051 14.6532 43.2808 14.681 43.2218 14.6833C43.1894 14.6844 43.1559 14.6844 43.1235 14.6844C42.7996 14.6844 42.4768 14.6844 42.1529 14.6844L42.1517 14.6867ZM37.5997 11.1941C38.0624 11.1941 38.524 11.1941 38.9867 11.1941C39.1278 11.1941 39.1324 11.1848 39.07 11.0564C38.8189 10.5372 38.5668 10.019 38.3169 9.49977C38.1943 9.24534 38.0763 8.98975 37.9548 8.73531C37.8542 8.52483 37.7512 8.3155 37.6506 8.10617C37.6286 8.05991 37.6089 8.05875 37.5858 8.10386C37.5765 8.12236 37.5673 8.14086 37.5592 8.15937C37.388 8.51673 37.2168 8.87525 37.0444 9.23146C36.7459 9.84557 36.4475 10.4597 36.1479 11.0726C36.0923 11.1871 36.0958 11.1941 36.2254 11.1941C36.6835 11.1941 37.1416 11.1941 37.5997 11.1941Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M64.69 20.2764C64.69 20.7055 64.6958 21.1357 64.6877 21.5648C64.683 21.8181 64.6668 22.0702 64.5928 22.3165C64.439 22.8266 64.0873 23.0972 63.5702 23.1527C63.281 23.1839 62.9883 23.177 62.7141 23.0509C62.4041 22.9087 62.2075 22.6704 62.1311 22.3374C62.1091 22.2402 62.1253 22.2229 62.2271 22.2182C62.2676 22.2159 62.3093 22.2194 62.3497 22.2182C62.4203 22.2148 62.4631 22.2425 62.4897 22.3108C62.6389 22.6889 62.9386 22.8312 63.3238 22.8347C63.4059 22.8347 63.4881 22.8358 63.569 22.8242C63.9496 22.7653 64.1845 22.5478 64.2793 22.172C64.3372 21.9395 64.3487 21.7036 64.3441 21.4653C64.3441 21.4457 64.351 21.4191 64.3279 21.4121C64.3048 21.4052 64.2967 21.4318 64.2839 21.4468C63.7368 22.1222 62.6644 22.0124 62.263 21.2236C61.9356 20.5806 61.9622 19.9202 62.3162 19.2969C62.5522 18.8817 62.942 18.6886 63.4267 18.7013C63.6084 18.7059 63.7854 18.7279 63.9519 18.8065C64.0722 18.8632 64.1787 18.936 64.2666 19.0367C64.2839 19.0563 64.2944 19.0991 64.3279 19.0841C64.3568 19.0725 64.3429 19.0332 64.3476 19.0054C64.3534 18.9696 64.358 18.9326 64.3626 18.8956C64.3695 18.8435 64.3649 18.7811 64.4343 18.7695C64.5107 18.7556 64.5917 18.7406 64.6599 18.788C64.7062 18.8192 64.6911 18.8771 64.6923 18.9256C64.6923 19.3755 64.6923 19.8254 64.6923 20.2753H64.69V20.2764ZM64.3441 20.2591C64.3545 20.0764 64.3383 19.8774 64.2897 19.682C64.203 19.3316 63.9947 19.1037 63.6269 19.0459C63.5505 19.0343 63.4719 19.0355 63.3944 19.032C62.9814 19.0135 62.6806 19.2518 62.5406 19.608C62.3775 20.0209 62.3636 20.4453 62.5024 20.8686C62.5927 21.1427 62.7604 21.3589 63.0323 21.4804C63.2104 21.5602 63.3944 21.5648 63.5852 21.5347C63.8929 21.4862 64.1127 21.3289 64.2307 21.0363C64.3291 20.7923 64.351 20.5378 64.3441 20.2591Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M33.5486 21.8814C33.1518 21.8814 32.7539 21.8814 32.3571 21.8814C32.209 21.8814 32.1963 21.8699 32.1963 21.7195C32.1963 20.3861 32.1963 19.0526 32.1963 17.718C32.1963 17.5607 32.2055 17.5526 32.364 17.5526C33.146 17.5526 33.928 17.5526 34.7101 17.5526C34.7644 17.5526 34.8373 17.5295 34.8616 17.5977C34.8917 17.6833 34.8917 17.7747 34.857 17.8614C34.8454 17.8903 34.8119 17.8949 34.7829 17.8972C34.7424 17.8996 34.7008 17.9007 34.6603 17.9007C34.0171 17.9007 33.3751 17.9007 32.7319 17.9007C32.5804 17.9007 32.5699 17.91 32.5699 18.0603C32.5699 18.4616 32.5699 18.8618 32.5699 19.2631C32.5699 19.4123 32.5792 19.4192 32.7331 19.4204C33.3427 19.4204 33.9535 19.4204 34.5631 19.4215C34.6245 19.4215 34.7077 19.3926 34.7413 19.4655C34.7772 19.5441 34.7644 19.6343 34.7471 19.7188C34.739 19.7558 34.7043 19.765 34.6696 19.7673C34.6291 19.7696 34.5874 19.7696 34.5469 19.7696C33.9454 19.7696 33.3427 19.7696 32.7412 19.7696C32.5746 19.7696 32.5699 19.7743 32.5699 19.9431C32.5699 20.4173 32.5699 20.8926 32.5699 21.3668C32.5699 21.5252 32.5769 21.5322 32.7365 21.5322C33.404 21.5322 34.0715 21.5322 34.739 21.5322C34.7795 21.5322 34.8211 21.5322 34.8616 21.5345C34.8859 21.5345 34.9148 21.5356 34.9299 21.5576C34.9854 21.6409 34.931 21.723 34.9241 21.8063C34.9194 21.8595 34.8755 21.878 34.8257 21.8803C34.7887 21.8814 34.7517 21.8826 34.7147 21.8826C34.326 21.8826 33.9373 21.8826 33.5475 21.8826L33.5486 21.8814Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M54.2717 20.5908C54.2717 20.836 54.2717 21.0823 54.2717 21.3275C54.2717 21.4501 54.274 21.5727 54.2914 21.6953C54.3145 21.8618 54.2914 21.8826 54.1283 21.8826C54.1121 21.8826 54.0959 21.8815 54.0797 21.8826C54.0045 21.8849 53.9698 21.8445 53.9559 21.7751C53.9386 21.6929 53.9559 21.6039 53.9039 21.5299C53.846 21.5866 53.7905 21.6455 53.7303 21.6999C53.5557 21.8583 53.3417 21.9173 53.1138 21.9462C52.8593 21.9786 52.614 21.9497 52.3838 21.8364C52.0518 21.6733 51.8667 21.3009 51.8979 20.9308C51.935 20.4971 52.1779 20.2496 52.5712 20.1189C52.7783 20.0495 52.9946 20.0218 53.2121 20.016C53.4087 20.0102 53.6054 20.0114 53.8021 20.0148C53.8911 20.016 53.9293 19.9801 53.9258 19.8911C53.9212 19.7766 53.9282 19.6621 53.9108 19.5488C53.8668 19.2712 53.7026 19.1116 53.4342 19.0549C53.2398 19.0133 53.0443 19.0156 52.85 19.0607C52.6499 19.1058 52.5168 19.2238 52.4451 19.4169C52.3711 19.6135 52.3584 19.617 52.1513 19.6031C52.0668 19.5973 52.0437 19.5592 52.0599 19.484C52.1212 19.2076 52.2542 18.9832 52.5122 18.8445C52.7204 18.7334 52.946 18.6999 53.1774 18.7045C53.3486 18.708 53.5198 18.7195 53.6864 18.7762C54.0091 18.8861 54.1896 19.1139 54.2498 19.4424C54.2683 19.543 54.2764 19.6448 54.2752 19.7477C54.2729 20.0299 54.2752 20.3121 54.2752 20.5954L54.2717 20.5908ZM53.4978 20.3132C53.4122 20.3132 53.3255 20.3086 53.2399 20.3132C53.0316 20.3259 52.8234 20.3445 52.6279 20.43C52.3699 20.5434 52.2404 20.7631 52.26 21.0499C52.2797 21.3206 52.4474 21.5241 52.7112 21.5981C52.9102 21.6536 53.1091 21.6363 53.3058 21.5865C53.5823 21.516 53.7616 21.3414 53.846 21.0684C53.912 20.8545 53.927 20.6347 53.9224 20.4127C53.9212 20.3398 53.8854 20.3098 53.8148 20.3109C53.7084 20.3121 53.6019 20.3109 53.4955 20.3109L53.4978 20.3132Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M46.9443 19.6011C46.9443 18.9095 46.9443 18.2191 46.9443 17.5275C46.9443 17.3228 47.0137 17.2696 47.2162 17.3158C47.2625 17.3262 47.2891 17.3505 47.2902 17.3991C47.2914 17.4361 47.2925 17.4731 47.2925 17.509C47.2925 18.0167 47.2925 18.5232 47.2925 19.0309C47.2925 19.0599 47.2902 19.0888 47.2925 19.1165C47.2948 19.1385 47.2798 19.172 47.311 19.1801C47.3307 19.1859 47.3411 19.157 47.3538 19.1408C47.6616 18.7407 48.078 18.6412 48.5488 18.7279C48.9919 18.81 49.2765 19.1605 49.3308 19.6497C49.347 19.7966 49.3551 19.9423 49.3551 20.0903C49.3528 20.6304 49.3551 21.1705 49.354 21.7106C49.354 21.7661 49.3713 21.8378 49.3066 21.8632C49.2221 21.8968 49.1296 21.8933 49.0428 21.8632C49.0127 21.8528 49.0093 21.8193 49.0081 21.7904C49.0069 21.7534 49.0058 21.7164 49.0058 21.6805C49.0058 21.1242 49.0058 20.5679 49.0058 20.0117C49.0058 19.8636 48.9873 19.7179 48.9479 19.5757C48.8519 19.2345 48.6136 19.0425 48.2573 19.0402C47.7911 19.0367 47.4522 19.2299 47.3365 19.7619C47.3076 19.8937 47.2937 20.0279 47.2937 20.1632C47.2949 20.6743 47.2949 21.1855 47.2937 21.6967C47.2937 21.7545 47.3203 21.8332 47.2532 21.8644C47.1711 21.9014 47.0774 21.8922 46.9895 21.8679C46.9582 21.8598 46.9513 21.8274 46.949 21.7973C46.9466 21.7568 46.9455 21.7152 46.9455 21.6747C46.9455 20.9831 46.9455 20.2927 46.9455 19.6011H46.9443Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M57.0905 20.3837C57.0905 20.0159 57.0905 19.647 57.0905 19.2792C57.0905 19.1566 57.094 19.034 57.0778 18.9114C57.0628 18.7946 57.0928 18.7669 57.212 18.7622C57.2409 18.7622 57.2698 18.7622 57.2976 18.7622C57.3763 18.7622 57.4179 18.7969 57.4214 18.879C57.426 18.9692 57.4445 19.0571 57.441 19.1485C57.441 19.167 57.4341 19.1959 57.4561 19.2017C57.4792 19.2086 57.4862 19.1797 57.4966 19.1635C57.6504 18.9218 57.8667 18.7715 58.149 18.7252C58.4278 18.679 58.7043 18.6813 58.9634 18.8201C59.1589 18.9241 59.3 19.0791 59.3799 19.285C59.447 19.4573 59.4828 19.6377 59.4828 19.8251C59.4817 20.4507 59.4828 21.0776 59.4817 21.7032C59.4817 21.7611 59.5071 21.8397 59.4342 21.8663C59.3533 21.8964 59.2653 21.8894 59.1809 21.8675C59.1497 21.8594 59.1404 21.8293 59.1381 21.7992C59.1358 21.7588 59.1346 21.7171 59.1346 21.6766C59.1346 21.0914 59.1346 20.5063 59.1346 19.9211C59.1346 19.8147 59.1277 19.7094 59.1045 19.6042C59.0097 19.167 58.7031 19.0074 58.2994 19.0421C57.8991 19.0756 57.6481 19.3 57.5255 19.6759C57.4734 19.8366 57.4399 19.9997 57.441 20.1697C57.4422 20.6693 57.441 21.1678 57.441 21.6674C57.441 21.8733 57.3728 21.9253 57.168 21.8779C57.1218 21.8675 57.0952 21.8432 57.094 21.7946C57.0928 21.7541 57.0917 21.7125 57.0917 21.672C57.0917 21.2418 57.0917 20.8127 57.0917 20.3825L57.0905 20.3837Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M43.3337 18.7025C43.8485 18.7152 44.2441 19.0055 44.3957 19.5225C44.4049 19.5537 44.4118 19.5861 44.4176 19.6185C44.4304 19.6867 44.4014 19.7191 44.3332 19.7203C44.2927 19.7203 44.2511 19.7191 44.2106 19.7214C44.1238 19.7272 44.0775 19.689 44.0498 19.6058C43.9827 19.4068 43.8716 19.2368 43.6807 19.1339C43.2169 18.8818 42.6442 19.0657 42.4163 19.5618C42.1757 20.0846 42.1803 20.6223 42.4395 21.1381C42.7183 21.6944 43.351 21.7349 43.7282 21.477C43.8739 21.3775 43.9537 21.233 44.0278 21.0803C44.0521 21.0306 44.059 20.9612 44.1261 20.9485C44.2106 20.9334 44.2985 20.9311 44.3829 20.9496C44.4327 20.96 44.4199 21.0075 44.4084 21.0421C44.2615 21.4886 43.9977 21.8297 43.4968 21.9257C42.7865 22.0622 42.2012 21.7164 41.9883 21.026C41.8021 20.4211 41.8356 19.8255 42.178 19.2773C42.4267 18.8772 42.8062 18.6944 43.3337 18.7014V18.7025Z"
          fill="white"
        />

        <path
          className="path-3"
          d="M39.5988 21.8827C39.4808 21.9104 39.4021 21.8422 39.3293 21.7312C39.091 21.3692 38.8434 21.013 38.5982 20.6544C38.5345 20.5608 38.5311 20.5619 38.4663 20.6568C38.2187 21.0234 37.9723 21.39 37.7248 21.7566C37.6507 21.8665 37.4298 21.9312 37.3129 21.8746C37.2516 21.8445 37.2886 21.8063 37.3106 21.774C37.4425 21.582 37.5767 21.3911 37.7097 21.1992C37.9018 20.9239 38.0926 20.6475 38.287 20.3746C38.3333 20.3098 38.3136 20.2635 38.2754 20.2092C38.0545 19.89 37.8335 19.5696 37.6137 19.2493C37.5281 19.1244 37.4413 19.0006 37.3581 18.8746C37.3083 18.8006 37.321 18.7716 37.4078 18.7647C37.4772 18.7589 37.5466 18.7624 37.616 18.7647C37.6912 18.767 37.7294 18.8214 37.7664 18.8769C37.9908 19.2134 38.2164 19.5511 38.442 19.8877C38.4559 19.9085 38.4686 19.9293 38.4825 19.949C38.5392 20.0265 38.5496 20.0276 38.6028 19.9513C38.7752 19.7073 38.9464 19.4621 39.1176 19.2181C39.1951 19.107 39.2714 18.996 39.3489 18.8861C39.4276 18.7751 39.6289 18.7184 39.755 18.7705C39.8198 18.7971 39.7851 18.8364 39.7631 18.8676C39.6786 18.9879 39.593 19.1082 39.5063 19.2273C39.2807 19.5396 39.0574 19.8518 38.8284 20.1606C38.7682 20.2416 38.7636 20.3063 38.8226 20.3908C39.1245 20.8233 39.4218 21.2605 39.7203 21.6953C39.7272 21.7057 39.7353 21.715 39.7411 21.7254C39.7619 21.767 39.8209 21.8029 39.7908 21.8538C39.7619 21.9012 39.7006 21.8757 39.6532 21.8815C39.6416 21.8827 39.6289 21.8815 39.5965 21.8815L39.5988 21.8827Z"
          fill="white"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_250_8168">
          <rect
            className="rect"
            fill="white"
            height="23.3153"
            transform="translate(0.962891 0.123047)"
            width="68.8631"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
